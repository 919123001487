import { Badge, Helper, Table } from 'akeneo-design-system';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { BCVariantAttribute } from '../../model/BCVariantAttribute';
import { useSelector } from '../../store/StoreProvider';
import { State } from '../../store/reducer';

const BCAttributeContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 26px;
`;

const PimAttributeCell = styled(Table.Cell)`
    overflow: initial;
`;

const PimAttributeContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 6px;
    width: 300px;
`;

type Props = {
    bcVariantAttribute: BCVariantAttribute;
    children: React.ReactNode;
};

export const MappingVariantItem = ({ bcVariantAttribute, children }: Props) => {
    const isAttributeMapped = useSelector((state: State) => {
        return (
            state.productVariantMapping.get(bcVariantAttribute.code) !==
            undefined
        );
    });

    return (
        <Table.Row data-testid={bcVariantAttribute.code}>
            <Table.Cell>
                <BCAttributeContainer>
                    <div>{bcVariantAttribute.label}</div>
                    <Helper inline level='info'>
                        {bcVariantAttribute.isRequired ? (
                            <FormattedMessage
                                id='otgkyu'
                                defaultMessage='Required, source data PIM allowed attribute types: {types}'
                                values={{
                                    types: bcVariantAttribute.pimAllowedTypes.join(
                                        ', '
                                    ),
                                }}
                            />
                        ) : (
                            <FormattedMessage
                                id='L9laDs'
                                defaultMessage='Source data PIM allowed attribute types: {types}'
                                values={{
                                    types: bcVariantAttribute.pimAllowedTypes.join(
                                        ', '
                                    ),
                                }}
                            />
                        )}
                        {bcVariantAttribute.constraint && (
                            <>
                                <br />
                                <FormattedMessage
                                    id='u8wsGp'
                                    defaultMessage='Data value constraint: {constraint}'
                                    values={{
                                        constraint:
                                            bcVariantAttribute.constraint,
                                    }}
                                />
                            </>
                        )}
                    </Helper>
                </BCAttributeContainer>
            </Table.Cell>

            <PimAttributeCell>
                <PimAttributeContainer>{children}</PimAttributeContainer>
            </PimAttributeCell>

            <Table.Cell>
                {isAttributeMapped && (
                    <Badge level='primary'>
                        <FormattedMessage
                            id='U78NhE'
                            defaultMessage='Complete'
                        />
                    </Badge>
                )}
            </Table.Cell>
        </Table.Row>
    );
};
