import en from './compiled/en.json';
import fr from './compiled/fr.json';

export const getLocaleMessages = (locale: string) => {
    switch (locale) {
        case 'fr':
            return fr;
        default:
            return en;
    }
};
