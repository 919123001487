import { useQuery } from 'react-query';
import { getPimAttributes } from '../../api/getPimAttributes';
import { PimAttribute } from '../model/PimAttribute';
import { BCVariantAttribute } from '../model/BCVariantAttribute';

export const useAllowedPimAttributes = (
    bcVariantAttribute: BCVariantAttribute
) => {
    const pimAttributesQuery = useQuery('fetchPimAttributes', getPimAttributes);
    let allowedPimAttribute: PimAttribute[] = [];
    if (pimAttributesQuery.isSuccess) {
        allowedPimAttribute = Object.values(pimAttributesQuery.data).filter(
            ({ type }) => bcVariantAttribute.pimAllowedTypes.includes(type)
        );
    }
    return allowedPimAttribute;
};
