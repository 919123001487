import { Table } from 'akeneo-design-system';
import styled from 'styled-components';
import { DateTimeLabel } from '../../../../component';
import { JobLog } from '../../model/JobLog';
import { JobLogStatusBadge } from '../component/JobLogStatusBadge';

const Message = styled.div`
    white-space: normal;
`;

type Props = {
    log: JobLog;
};

export const JobLogItem = ({ log }: Props) => {
    return (
        <Table.Row>
            <Table.Cell>
                <DateTimeLabel dateTime={log.createdAt} />
            </Table.Cell>
            <Table.Cell>
                <JobLogStatusBadge status={log.status} />
            </Table.Cell>
            <Table.Cell>
                <Message>{log.message}</Message>
            </Table.Cell>
        </Table.Row>
    );
};
