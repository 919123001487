import { Badge } from 'akeneo-design-system';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { JobExecutionStatus } from '../../model/JobExecutionStatus';

type Props = {
    status: JobExecutionStatus;
};

export const JobExecutionStatusBadge = ({ status }: Props) => {
    switch (status) {
        case JobExecutionStatus.Pending:
            return (
                <Badge level='tertiary'>
                    <FormattedMessage id='eKEL/g' defaultMessage='Pending' />
                </Badge>
            );
        case JobExecutionStatus.InProgress:
            return (
                <Badge level='secondary'>
                    <FormattedMessage
                        id='q1WWIr'
                        defaultMessage='In progress'
                    />
                </Badge>
            );
    }

    return null;
};
