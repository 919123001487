import { Table } from 'akeneo-design-system';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { TableSkeleton } from '../../../../component';

const Container = styled.div`
    margin-top: 10px;
`;

const Header = () => (
    <Table.Header>
        <Table.HeaderCell>
            <FormattedMessage id='P7PLVj' defaultMessage='Date' />
        </Table.HeaderCell>
        <Table.HeaderCell>
            <FormattedMessage id='Z7x+nz' defaultMessage='Execution time' />
        </Table.HeaderCell>
        <Table.HeaderCell>
            <FormattedMessage id='+U6ozc' defaultMessage='Type' />
        </Table.HeaderCell>
        <Table.HeaderCell>
            <FormattedMessage id='3tyW89' defaultMessage='Reference date' />
        </Table.HeaderCell>
        <Table.HeaderCell>
            <FormattedMessage id='nso3Mj' defaultMessage='Scope' />
        </Table.HeaderCell>
        <Table.HeaderCell>
            <FormattedMessage id='tzMNF3' defaultMessage='Status' />
        </Table.HeaderCell>
        <Table.HeaderCell>
            <FormattedMessage id='T7Ry38' defaultMessage='Message' />
        </Table.HeaderCell>
        <Table.HeaderCell></Table.HeaderCell>
    </Table.Header>
);

type Props = {
    children: React.ReactNode;
};

export const JobHistoryList = (props: Props) => {
    return (
        <Container>
            <Table>
                <Header />
                <Table.Body>
                    <>{props.children}</>
                </Table.Body>
            </Table>
        </Container>
    );
};

export const JobHistoryListSkeleton = () => {
    return (
        <Container>
            <TableSkeleton>
                <Header />
                <Table.Body>
                    {new Array(5).fill(0).map((v, i) => (
                        <Table.Row key={i}>
                            <TableSkeleton.Cell colSpan={7} />
                        </Table.Row>
                    ))}
                </Table.Body>
            </TableSkeleton>
        </Container>
    );
};
