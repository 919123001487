import { apiFetch } from '../../../../util/apiFetch';

export type Result = {
    [code: string]: {
        code: string;
        type: string;
        label: string;
        localizable: boolean;
        scopable: boolean;
    };
};

export const getPimAttributes = () =>
    apiFetch<Result>('mapping/pim-attributes');
