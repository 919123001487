import {
    Field,
    Helper,
    Link,
    SectionTitle,
    SelectInput,
    Tag,
    Tags,
} from 'akeneo-design-system';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useQuery } from 'react-query';
import styled from 'styled-components';
import { Skeleton } from '../../../component';
import { getConnectorConfigOptions } from '../api/getConnectorConfigOptions';
import { ConnectorConfig } from '../model/ConnectorConfig';
import { useDispatch, useSelector } from '../store/StoreProvider';

const LabelSkeleton = styled(Skeleton)`
    height: 20px;
    margin-bottom: 10px;
    width: 25%;
`;

const FieldSkeleton = styled(Skeleton)`
    height: 40px;
`;

const defaultConnectorConfig: ConnectorConfig = {
    channel: null,
    locale: null,
    rootCategory: null,
    currency: null,
};

export const ConnectorConfigForm = () => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const { connectorConfigOptions, connectorConfig, errors } = useSelector(
        (state) => ({
            connectorConfigOptions: state.connectorConfigOptions,
            connectorConfig:
                state.connectorConfigForm.data || defaultConnectorConfig,
            errors: state.connectorConfigForm.errors,
        })
    );

    const { isFetching } = useQuery(
        'Configuration/getConnectorConfigOptions',
        getConnectorConfigOptions,
        {
            onSuccess: (connectorConfigOptions) =>
                dispatch({
                    type: 'getConnectorConfigOptions/fulfilled',
                    connectorConfigOptions,
                }),
        }
    );

    const handleChange = (connectorConfig: ConnectorConfig) =>
        dispatch({
            type: 'connectorConfigForm/changed',
            connectorConfig,
        });

    const requiredLabel = intl.formatMessage({
        id: 'VG94fP',
        defaultMessage: '(required)',
    });

    const mapError = (errors: readonly string[]) =>
        errors.map((error, idx) => (
            <Helper key={idx} inline level='error'>
                {error}
            </Helper>
        ));

    return (
        <>
            <SectionTitle>
                <Tags>
                    <Tag tint='purple'>Akeneo</Tag>
                </Tags>
                <SectionTitle.Title>
                    <FormattedMessage
                        id='kL7zpH'
                        defaultMessage='Main app configuration'
                    />
                </SectionTitle.Title>
                <SectionTitle.Spacer />
            </SectionTitle>

            <Helper level='info'>
                <FormattedMessage
                    id='X8qWvC'
                    defaultMessage='To set up your Akeneo PIM sources,'
                />{' '}
                <Link href='https://help.akeneo.com/bigcommerce/articles/01-configuration-set-up.html'>
                    <FormattedMessage
                        id='+z9ImG'
                        defaultMessage='check out our Help Center for more information.'
                    />
                </Link>
            </Helper>

            <br />

            <form>
                {!isFetching ? (
                    <Field
                        label={intl.formatMessage({
                            id: 'RcxFT1',
                            defaultMessage: 'Locale',
                        })}
                        requiredLabel={requiredLabel}
                    >
                        <SelectInput
                            placeholder={intl.formatMessage({
                                id: '15k9nP',
                                defaultMessage: 'Select the locale',
                            })}
                            openLabel=''
                            value={connectorConfig.locale}
                            data-testid='pim-locale'
                            onChange={(locale: string | null) =>
                                handleChange({
                                    ...connectorConfig,
                                    locale,
                                })
                            }
                            invalid={!!errors?.locale}
                            emptyResultLabel={intl.formatMessage({
                                id: 'hST08B',
                                defaultMessage: 'No locale found',
                            })}
                        >
                            {Object.entries(
                                connectorConfigOptions?.locale || {}
                            ).map(([code, label]) => (
                                <SelectInput.Option key={code} value={code}>
                                    {label}
                                </SelectInput.Option>
                            ))}
                        </SelectInput>
                        {errors?.locale && mapError(errors.locale)}
                    </Field>
                ) : (
                    <>
                        <LabelSkeleton />
                        <FieldSkeleton />
                    </>
                )}

                <br />

                {!isFetching ? (
                    <Field
                        label={intl.formatMessage({
                            id: 'KeO51o',
                            defaultMessage: 'Channel',
                        })}
                        requiredLabel={requiredLabel}
                    >
                        <SelectInput
                            placeholder={intl.formatMessage({
                                id: 'lfOUPC',
                                defaultMessage: 'Select the channel',
                            })}
                            openLabel=''
                            value={connectorConfig.channel}
                            data-testid='pim-channel'
                            onChange={(channel: string | null) =>
                                handleChange({
                                    ...connectorConfig,
                                    channel,
                                })
                            }
                            invalid={!!errors?.channel}
                            emptyResultLabel={intl.formatMessage({
                                id: '2zikCs',
                                defaultMessage: 'No channel found',
                            })}
                        >
                            {Object.entries(
                                connectorConfigOptions?.channel || {}
                            ).map(([code, label]) => (
                                <SelectInput.Option key={code} value={code}>
                                    {label}
                                </SelectInput.Option>
                            ))}
                        </SelectInput>
                        {errors?.channel && mapError(errors.channel)}
                    </Field>
                ) : (
                    <>
                        <LabelSkeleton />
                        <FieldSkeleton />
                    </>
                )}

                <br />
                {!isFetching ? (
                    <Field
                        label={intl.formatMessage({
                            id: '55hdQy',
                            defaultMessage: 'Currency',
                        })}
                        requiredLabel={requiredLabel}
                    >
                        <SelectInput
                            placeholder={intl.formatMessage({
                                id: 'XePuFG',
                                defaultMessage: 'Select the currency',
                            })}
                            openLabel=''
                            value={connectorConfig.currency}
                            data-testid='pim-currency'
                            onChange={(currency: string | null) =>
                                handleChange({
                                    ...connectorConfig,
                                    currency,
                                })
                            }
                            invalid={!!errors?.currency}
                            emptyResultLabel={intl.formatMessage({
                                id: 'zzYiAk',
                                defaultMessage: 'No currency found',
                            })}
                        >
                            {Object.entries(
                                connectorConfigOptions?.currency || {}
                            ).map(([code, label]) => (
                                <SelectInput.Option key={code} value={code}>
                                    {label}
                                </SelectInput.Option>
                            ))}
                        </SelectInput>
                        {errors?.currency && mapError(errors.currency)}
                    </Field>
                ) : (
                    <>
                        <LabelSkeleton />
                        <FieldSkeleton />
                    </>
                )}

                <br />

                {!isFetching ? (
                    <Field
                        label={intl.formatMessage({
                            id: 'yUE0FE',
                            defaultMessage: 'Main category',
                        })}
                        requiredLabel={requiredLabel}
                    >
                        <SelectInput
                            placeholder={intl.formatMessage({
                                id: 'fuxS0B',
                                defaultMessage: 'Select the main category',
                            })}
                            openLabel=''
                            value={connectorConfig.rootCategory}
                            data-testid='pim-root-category'
                            onChange={(rootCategory: string | null) =>
                                handleChange({
                                    ...connectorConfig,
                                    rootCategory,
                                })
                            }
                            invalid={!!errors?.rootCategory}
                            emptyResultLabel={intl.formatMessage({
                                id: 'bn5V11',
                                defaultMessage: 'No category found',
                            })}
                        >
                            {Object.entries(
                                connectorConfigOptions?.rootCategory || {}
                            ).map(([code, label]) => (
                                <SelectInput.Option key={code} value={code}>
                                    {label}
                                </SelectInput.Option>
                            ))}
                        </SelectInput>
                        {errors?.rootCategory && mapError(errors.rootCategory)}
                    </Field>
                ) : (
                    <>
                        <LabelSkeleton />
                        <FieldSkeleton />
                    </>
                )}

                <br />
            </form>
        </>
    );
};
