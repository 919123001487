import { FormattedMessage } from 'react-intl';
import { JobType } from '../model/JobType';

type Props = {
    type: JobType;
};

export const JobTypeLabel = ({ type }: Props) => {
    switch (type) {
        case JobType.Differential:
            return (
                <FormattedMessage id='Zz4crq' defaultMessage='Delta import' />
            );
        case JobType.Full:
            return (
                <FormattedMessage id='TTuQP0' defaultMessage='Full import' />
            );
    }
};
