import { apiFetch, BadRequestError } from '../../../util/apiFetch';

type Credentials = {
    storeHash: string;
    clientId: string;
    accessToken: string;
};

export const registerBCCredentials = (credentials: Credentials) =>
    apiFetch('configuration/registerBcCredentials', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            store_hash: credentials.storeHash,
            client_id: credentials.clientId,
            access_token: credentials.accessToken,
        }),
    });

export const isRegisterBCCredentialsValidationError = (
    error: unknown
): error is BadRequestError<{
    errors: {
        storeHash: string[];
        clientId: string[];
        accessToken: string[];
    };
}> => error instanceof BadRequestError;
