import { apiFetch } from '../../../util/apiFetch';
import { JobLog } from '../model/JobLog';

type Result = Array<{
    id: string;
    status: 'WARNING' | 'ERROR';
    message: string;
    created_at: string;
}>;

export const fetchJobLogs = (jobId: string): Promise<JobLog[]> =>
    apiFetch<Result>(
        `synchronization/${jobId}/retrieveSynchronizationLogs`
    ).then((result) =>
        result.map((jobLog) => ({ ...jobLog, createdAt: jobLog.created_at }))
    );
