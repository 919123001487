import { Field, Helper, SelectInput } from 'akeneo-design-system';
import { FormattedMessage, useIntl } from 'react-intl';
import { DateTimeInput, Spacer } from '../../../../component';
import { useDispatch, useSelector } from '../../store/StoreProvider';
import styled from 'styled-components';
import { JobScope } from '../../model/JobScope';
import { CompletenessFilterInput } from './CompletenessFilterInput';

const ModalContent = styled.div<{ scope: JobScope }>`
    min-height: ${({ scope }) =>
        scope === JobScope.Product ? '420px' : '230px'};
`;

const StyledHelper = styled(Helper)`
    font-size: 11px;
`;

type Props = {
    scope: JobScope;
    enabledProductsOnly: boolean;
    setEnabledProductsOnly: (value: boolean) => void;
    minCompleteness: number | null;
    setMinCompleteness: (value: number) => void;
};

export const FiltersForm = ({
    scope,
    enabledProductsOnly,
    setEnabledProductsOnly,
    minCompleteness,
    setMinCompleteness,
}: Props) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const filters = useSelector((state) => state.filters);

    const handleMethodChange = (method: string) =>
        dispatch({
            type: 'filters/changed',
            payload: {
                ...filters,
                method: method as 'FULL' | 'DIFFERENTIAL',
            },
        });

    const handleDateTimeChange = (updated: string) =>
        dispatch({
            type: 'filters/changed',
            payload: {
                ...filters,
                updated,
            },
        });

    const handleStatusChange = (status: string) => {
        setEnabledProductsOnly(status === 'enabled');
    };

    const handleCompletenessChange = (value: string) => {
        setMinCompleteness(parseInt(value));
    };

    return (
        <ModalContent scope={scope}>
            <Spacer />

            <FormattedMessage
                id='Zaj5n6'
                defaultMessage='If you choose the full import, the whole catalog will be imported. For the first import, please use full mode only.'
            />

            <br />
            <br />
            <Field
                label={intl.formatMessage({
                    id: 'MMGQKE',
                    defaultMessage: 'Import process (required)',
                })}
            >
                <SelectInput
                    value={filters.method}
                    onChange={handleMethodChange}
                    clearable={false}
                    emptyResultLabel=''
                    openLabel=''
                >
                    <SelectInput.Option value='DIFFERENTIAL'>
                        <FormattedMessage id='kDNmhM' defaultMessage='Delta' />
                    </SelectInput.Option>

                    <SelectInput.Option value='FULL'>
                        <FormattedMessage id='NYnkyd' defaultMessage='Full' />
                    </SelectInput.Option>
                </SelectInput>
            </Field>
            {filters.method === 'DIFFERENTIAL' && (
                <>
                    <br />
                    <div>
                        <Field
                            label={intl.formatMessage({
                                id: '6AwQAz',
                                defaultMessage: 'Select the referential date',
                            })}
                        >
                            <DateTimeInput
                                dateTime={filters.updated}
                                onChange={handleDateTimeChange}
                            />
                        </Field>
                    </div>
                </>
            )}
            <br />
            {scope === JobScope.Product && (
                <>
                    <Field
                        label={intl.formatMessage({
                            id: 'LB7HJ/',
                            defaultMessage: 'Status (required)',
                        })}
                    >
                        <SelectInput
                            value={enabledProductsOnly ? 'enabled' : 'all'}
                            onChange={handleStatusChange}
                            clearable={false}
                            emptyResultLabel=''
                            openLabel=''
                        >
                            <SelectInput.Option value='all'>
                                <FormattedMessage
                                    id='zQvVDJ'
                                    defaultMessage='All'
                                />
                            </SelectInput.Option>
                            <SelectInput.Option value='enabled'>
                                <FormattedMessage
                                    id='V52jNn'
                                    defaultMessage='Enabled'
                                />
                            </SelectInput.Option>
                        </SelectInput>
                        <StyledHelper level='info' inline>
                            <FormattedMessage
                                id='WOVZCQ'
                                defaultMessage='Apply this filter to import or update the products in the PIM’s ENABLED status only.'
                            />
                        </StyledHelper>
                    </Field>
                    <br />
                    <Field
                        label={intl.formatMessage({
                            id: 'aJbRki',
                            defaultMessage: 'Completeness',
                        })}
                    >
                        <CompletenessFilterInput
                            onChange={handleCompletenessChange}
                            minCompleteness={minCompleteness}
                        />
                        <StyledHelper level='info' inline>
                            <FormattedMessage
                                id='IExcXx'
                                defaultMessage='Apply this filter to import or update the products by their completeness.'
                            />
                        </StyledHelper>
                    </Field>
                </>
            )}
        </ModalContent>
    );
};
