import { TabBar, useTabBar } from 'akeneo-design-system';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { ValidConfigurationGuard } from '../Configuration';
import { InvalidConfiguration } from './component/InvalidConfiguration';
import { ExecutionTab } from './ExecutionTab/ExecutionTab';
import { initialState } from './store/reducer';
import { StoreProvider } from './store/StoreProvider';
import { HistoryTab } from './HistoryTab/HistoryTab';

const Container = styled.div`
    margin-top: 32px;
`;

export const Synchronization = () => {
    const [isCurrent, switchTo] = useTabBar('Execution');

    return (
        <ValidConfigurationGuard fallback={() => <InvalidConfiguration />}>
            <Container>
                <TabBar moreButtonTitle='More'>
                    <TabBar.Tab
                        isActive={isCurrent('Execution')}
                        onClick={() => switchTo('Execution')}
                    >
                        <FormattedMessage
                            id='1leDN6'
                            defaultMessage='Execution'
                        />
                    </TabBar.Tab>
                    <TabBar.Tab
                        isActive={isCurrent('History')}
                        onClick={() => switchTo('History')}
                    >
                        <FormattedMessage
                            id='djJp6c'
                            defaultMessage='History'
                        />
                    </TabBar.Tab>
                </TabBar>

                <StoreProvider initialState={initialState}>
                    {isCurrent('Execution') && <ExecutionTab />}
                    {isCurrent('History') && <HistoryTab />}
                </StoreProvider>
            </Container>
        </ValidConfigurationGuard>
    );
};
