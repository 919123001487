import React from 'react';
import styled from 'styled-components';
import { FlexColumn, FlexLayout } from '../../component/FlexLayout';
import { BCCredentialsForm } from './component/BCCredentialsForm';
import { ConnectorConfigForm } from './component/ConnectorConfigForm';
import { ConfigurationProvider } from './ConfigurationProvider';
import { SaveConfigurationButton } from './SaveConfigurationButton';
import { DisabledConfiguration } from './component/DisabledConfiguration';
import { RunningSynchronizationGuard } from '../Synchronization/RunningSynchronizationGuard';
import { BCStorefrontForm } from './component/BCStorefrontForm';
import { BCVariantDisplayForm } from './component/BCVariantDisplayForm';

const SaveButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`;

export const Configuration = () => {
    return (
        <RunningSynchronizationGuard fallback={() => <DisabledConfiguration />}>
            <ConfigurationProvider>
                <SaveButtonContainer>
                    <SaveConfigurationButton />
                </SaveButtonContainer>
                <FlexLayout>
                    <FlexColumn>
                        <ConnectorConfigForm />
                    </FlexColumn>
                    <FlexColumn>
                        <BCCredentialsForm />
                        <BCStorefrontForm />
                        <BCVariantDisplayForm />
                    </FlexColumn>
                </FlexLayout>
            </ConfigurationProvider>
        </RunningSynchronizationGuard>
    );
};
