import { Placeholder, UserGroupsIllustration } from 'akeneo-design-system';
import { useIntl } from 'react-intl';

export const Unauthorized = () => {
    const intl = useIntl();

    return (
        <Placeholder
            illustration={<UserGroupsIllustration />}
            title={intl.formatMessage({
                id: 'hCMGkK',
                defaultMessage: 'You are not logged in',
            })}
            size='large'
        >
            {intl.formatMessage({
                id: 'Md1c6a',
                defaultMessage:
                    'You are not logged in! Please login to your Akeneo PIM and access your BigCommerce App via Apps section.',
            })}
        </Placeholder>
    );
};
