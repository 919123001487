import { apiFetch } from '../../../../util/apiFetch';

export type Result = {
    [code: string]: {
        code: string;
        label: string;
    };
};

export const getPimFamilies = () => apiFetch<Result>('mapping/getPimFamilies');
