import { ClientErrorIllustration, Placeholder } from 'akeneo-design-system';
import React from 'react';
import { useIntl } from 'react-intl';

type Props = {
    isBCCredentialsInvalid: boolean;
    isConfigInvalid: boolean;
    isBcConfigInvalid: boolean;
};

export const InvalidConfiguration = ({
    isBCCredentialsInvalid,
    isConfigInvalid,
    isBcConfigInvalid,
}: Props) => {
    const intl = useIntl();

    const title = isBCCredentialsInvalid
        ? intl.formatMessage({
              id: 'anHbc8',
              defaultMessage:
                  'Please enter your BC credentials in the Configuration page, then you will be able to start mapping.',
          })
        : isConfigInvalid
        ? intl.formatMessage({
              id: 'cnNeyT',
              defaultMessage:
                  'Please finish the Main app configuration in the Configuration page, then you will be able to start mapping.',
          })
        : isBcConfigInvalid
        ? intl.formatMessage({
              id: 'YiK3qF',
              defaultMessage:
                  'Please finish the BC configuration in the Configuration page, then you will be able to start mapping.',
          })
        : '';

    return (
        <Placeholder
            illustration={<ClientErrorIllustration />}
            size='large'
            title={title}
        />
    );
};
