import { ApiIllustration, Placeholder } from 'akeneo-design-system';
import { useIntl } from 'react-intl';

export const AppNamespaceDeactivated = () => {
    const intl = useIntl();

    return (
        <Placeholder
            illustration={<ApiIllustration />}
            size='large'
            title={intl.formatMessage({
                id: 'ZybD0e',
                defaultMessage: 'This app is not yet activated',
            })}
        >
            {intl.formatMessage({
                id: 'wamWjO',
                defaultMessage:
                    'Please contact your Akeneo Account Manager to learn more about activating this app.',
            })}
        </Placeholder>
    );
};
