import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useQuery } from 'react-query';
import styled from 'styled-components';
import { apiFetch } from '../util/apiFetch';
import { ReturnIcon } from './ReturnIcon';

const StyledGoToPimLink = styled.a`
    color: rgb(103, 118, 138);
    font-size: 15px;
    font-weight: normal;
    height: 18px;
    text-decoration: none;
    margin: 0 5px 0 30px;
`;

export const GoToPimLink = () => {
    const result = useQuery('retrievePimUrl', () =>
        apiFetch<{ pim_url: string }>('retrievePimUrl')
    );

    if (!result.isSuccess) {
        return null;
    }

    return (
        <>
            <StyledGoToPimLink href={result.data.pim_url} target={'_blank'}>
                <FormattedMessage
                    id='gZmiLL'
                    defaultMessage='Go to Akeneo PIM'
                />
            </StyledGoToPimLink>
            <ReturnIcon />
        </>
    );
};
