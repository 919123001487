import { apiFetch } from '../../../../util/apiFetch';

type Result = {
    [bcAttributeCode: string]: {
        bcAttributeCode: string;
        data: string;
    };
};

export const fetchProductVariantsMapping = () =>
    apiFetch<Result>('mapping/retrieveProductVariantMapping');
