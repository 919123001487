import { Button } from 'akeneo-design-system';
import { isEqual } from 'lodash';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { DataCleanupConfirmationModal } from './component/DataCleanupConfirmationModal';
import { useSaveConfiguration } from './hook/useSaveConfiguration';
import { State } from './store/reducer';
import { useSelector } from './store/StoreProvider';

const selectIsDirty = (state: State) =>
    state.bcCredentialsForm.isDirty ||
    state.connectorConfigForm.isDirty ||
    state.connectorBcConfigForm.isDirty;

const selectIsDataCleanupRequired = (state: State) => {
    // Dot not display the confirmation modal if the connector has never been
    // fully configured (even if the cleanup WILL occur).
    if (
        null === state.bcCredentialsForm.defaultData ||
        null === state.connectorConfigForm.defaultData ||
        null === state.connectorBcConfigForm.defaultData
    ) {
        return false;
    }

    return (
        state.bcCredentialsForm.data?.storeHash !==
            state.bcCredentialsForm.defaultData?.storeHash ||
        !isEqual(
            state.connectorConfigForm.data?.rootCategory,
            state.connectorConfigForm.defaultData.rootCategory
        ) ||
        !isEqual(
            state.connectorConfigForm.data?.locale,
            state.connectorConfigForm.defaultData.locale
        ) ||
        !isEqual(
            state.connectorConfigForm.data?.channel,
            state.connectorConfigForm.defaultData.channel
        ) ||
        !isEqual(
            state.connectorBcConfigForm.data?.storefrontId,
            state.connectorBcConfigForm.defaultData.storefrontId
        )
    );
};

export const SaveConfigurationButton = () => {
    const { save, isSaving } = useSaveConfiguration();

    const isDirty = useSelector(selectIsDirty);
    const isDataCleanupRequired = useSelector(selectIsDataCleanupRequired);

    const [showConfirmation, setShowConfirmation] = useState(false);

    const handleSave = async () => {
        if (isDataCleanupRequired && !showConfirmation) {
            setShowConfirmation(true);

            return;
        }

        await save();
        setShowConfirmation(false);
    };

    const handleCancel = () => {
        setShowConfirmation(false);
    };

    return (
        <>
            <Button onClick={handleSave} disabled={isSaving || !isDirty}>
                <FormattedMessage id='jvo0vs' defaultMessage='Save' />
            </Button>

            {showConfirmation && (
                <DataCleanupConfirmationModal
                    onConfirm={handleSave}
                    onCancel={handleCancel}
                />
            )}
        </>
    );
};
