import { apiFetch } from '../../../util/apiFetch';

type Result = {
    data: {
        store_hash: string;
        client_id: string;
        access_token: string;
    } | null;
};

export const getBCCredentials = () =>
    apiFetch<Result>('configuration/getBcCredentials').then((result) => {
        if (result.data === null) {
            return null;
        }

        return {
            storeHash: result.data.store_hash,
            clientId: result.data.client_id,
            accessToken: result.data.access_token,
        };
    });
