import { apiFetch } from '../../../util/apiFetch';
import { JobExecution } from '../model/JobExecution';
import { JobScope } from '../model/JobScope';
import { JobExecutionStatus } from '../model/JobExecutionStatus';
import { JobType } from '../model/JobType';

const mapJobType = (type: 'FULL' | 'DIFFERENTIAL') => {
    switch (type) {
        case 'FULL':
            return JobType.Full;
        case 'DIFFERENTIAL':
            return JobType.Differential;
    }
};

const mapJobStatus = (
    status: 'PENDING' | 'DISABLED' | 'IN PROGRESS' | 'READY'
) => {
    switch (status) {
        case 'PENDING':
            return JobExecutionStatus.Pending;
        case 'DISABLED':
            return JobExecutionStatus.Disabled;
        case 'IN PROGRESS':
            return JobExecutionStatus.InProgress;
        case 'READY':
            return JobExecutionStatus.Ready;
    }
};

const createJobExecution = (
    scope: JobScope,
    result: JobExecutionResult
): JobExecution => {
    const status = mapJobStatus(result.status);

    if (
        status === JobExecutionStatus.Ready ||
        status === JobExecutionStatus.Disabled
    ) {
        return {
            scope,
            status,
        };
    }

    const type = mapJobType(result.type!);

    if (type === JobType.Full) {
        return {
            scope,
            status,
            type,
            createdAt: result.created_at!,
        };
    }

    return {
        scope,
        status,
        type,
        createdAt: result.created_at!,
        referenceDate: result.reference_date!,
    };
};

type JobExecutionResult = {
    status: 'READY' | 'DISABLED' | 'PENDING' | 'IN PROGRESS';
    type: 'FULL' | 'DIFFERENTIAL' | null;
    reference_date: string | null;
    created_at: string | null;
};

type Result = {
    [key in 'PRODUCT' | 'CATEGORY' | 'PRODUCT_VARIANT']: JobExecutionResult;
};

export const fetchJobExecutions = (): Promise<{
    [key in JobScope]: JobExecution;
}> =>
    apiFetch<Result>('synchronization/retrieveExecutingSynchronizations').then(
        (result) => ({
            [JobScope.Product]: createJobExecution(
                JobScope.Product,
                result.PRODUCT
            ),
            [JobScope.Category]: createJobExecution(
                JobScope.Category,
                result.CATEGORY
            ),
            [JobScope.ProductVariant]: createJobExecution(
                JobScope.ProductVariant,
                result.PRODUCT_VARIANT
            ),
        })
    );
