import { Table } from 'akeneo-design-system';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { TableSkeleton } from '../../../../component';

const MessageHeaderCell = styled(Table.HeaderCell)`
    width: 100%;
`;

const Header = () => (
    <Table.Header>
        <Table.HeaderCell>
            <FormattedMessage id='P7PLVj' defaultMessage='Date' />
        </Table.HeaderCell>
        <Table.HeaderCell>
            <FormattedMessage id='tzMNF3' defaultMessage='Status' />
        </Table.HeaderCell>
        <MessageHeaderCell>
            <FormattedMessage id='T7Ry38' defaultMessage='Message' />
        </MessageHeaderCell>
    </Table.Header>
);

type Props = {
    children: React.ReactNode;
};

export const JobLogList = ({ children }: Props) => {
    return (
        <Table>
            <Header />
            <Table.Body>
                <>{children}</>
            </Table.Body>
        </Table>
    );
};

export const JobLogListSkeleton = () => (
    <TableSkeleton>
        <Header />
        <Table.Body>
            {new Array(5).fill(0).map((v, i) => (
                <Table.Row key={i}>
                    <TableSkeleton.Cell colSpan={3} />
                </Table.Row>
            ))}
        </Table.Body>
    </TableSkeleton>
);
