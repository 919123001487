import {
    AssetsIllustration,
    Button,
    Checkbox,
    CloseIcon,
    getColor,
    Helper,
    Information,
    Table,
} from 'akeneo-design-system';
import { useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import { Loading } from '../../../../component';
import { getSelectedCustomFields } from '../api/getSelectedCustomFields';
import { retrievePimAttributesForCustomFieldsSelection } from '../api/retrievePimAttributesForCustomFieldsSelection';
import { saveCustomFields } from '../api/saveCustomFields';
import { NoPimCustomFields } from './NoPimCustomFields';
import { SelectedFilter } from './SelectedFilter';

const MAX_SELECTED_ATTRIBUTES = 200;

const Container = styled.div`
    background: white;
    left: 0;
    padding: 40px 80px;
    position: absolute;
    top: 0;
    width: 100%;
`;

const ButtonContainer = styled.div`
    position: absolute;
    right: 80px;
    top: 40px;
`;

const Title = styled.h1`
    color: ${({ theme }) => theme.color.brand100};
    font-size: ${({ theme }) => theme.fontSize.bigger};
    font-weight: normal;
    text-align: center;
    text-transform: uppercase;
`;

const AttributeLabel = styled.div`
    color: ${({ theme }) => theme.color.grey140};
    font-size: ${({ theme }) => theme.fontSize.title};
    padding-top: 1rem;
    text-align: center;
`;

const ColoredLabel = styled.span`
    color: ${({ theme }) => theme.color.brand100};
`;

const CloseIconThemed = styled(CloseIcon)`
    position: absolute;
    left: 43.5px;
    top: 40px;
    color: ${getColor('brand', 100)};
    cursor: pointer;
`;

const HelperSelection = styled.div`
    margin-top: 19px;
    margin-bottom: 45px;
`;

const InformationHelper = styled.div`
    margin-top: 20px;
`;

const NumberResultSelectFilter = styled.div`
    margin-top: 20px;
    width: 100%;
    justify-content: flex-end;
    display: flex;
`;
const Pipe = styled.span`
    margin: 0 20px 0 20px;
    width: 24px;
    height: 0px;
    border: 1px solid ${({ theme }) => theme.color.grey100};
    transform: rotate(90deg);
    flex: none;
    order: 1;
    flex-grow: 0;
`;
const NumberResultCustomFields = styled.span`
    color: ${({ theme }) => theme.color.brand100};
`;

export const CustomFieldsSelection = () => {
    const navigate = useNavigate();
    const { data: customFields } = useQuery(
        'retrievePimAttributesForCustomFieldsSelection',
        retrievePimAttributesForCustomFieldsSelection
    );

    const [state, setCustomFields] = useState<{ [code: string]: boolean }>({});

    const selectCustomField = (attributeCode: string, value: boolean) =>
        setCustomFields((state) => ({ ...state, [attributeCode]: value }));

    useQuery('retrieveCustomFields', getSelectedCustomFields, {
        onSuccess: (retrieveCustomFields) => {
            const selectedCustomFields = retrieveCustomFields.reduce(
                (data, customField) => ({ ...data, [customField.code]: true }),
                {}
            );
            setCustomFields(() => selectedCustomFields);
        },
    });

    const getSelectedAttribute = () => {
        const customFieldSelection: string[] = [];
        Object.keys(state).forEach((key) => {
            if (state[key] === true) {
                customFieldSelection.push(key);
            }
        });
        return customFieldSelection;
    };

    const maxAttributesReached =
        getSelectedAttribute().length >= MAX_SELECTED_ATTRIBUTES;

    const mutation = useMutation(saveCustomFields);

    const handleSave = async () => {
        const customFieldSelection = getSelectedAttribute();

        await mutation.mutateAsync(customFieldSelection);
        navigate('..');
    };

    const [selectedFilter, setSelectedFilter] = useState<
        'all' | 'selected' | 'unselected'
    >('all');

    const filteredCustomFields = useMemo(
        () =>
            customFields?.filter(
                (customField) =>
                    (selectedFilter === 'selected' &&
                        state[customField.code]) ||
                    (selectedFilter === 'unselected' &&
                        !state[customField.code]) ||
                    selectedFilter === 'all'
            ),
        [customFields, selectedFilter, state]
    );

    return (
        <Container>
            <ButtonContainer>
                <Button
                    level='primary'
                    ghost
                    onClick={handleSave}
                    disabled={mutation.isLoading}
                >
                    <FormattedMessage
                        id='svGivb'
                        defaultMessage='Confirm selection'
                    />
                </Button>
            </ButtonContainer>

            <Title>
                <FormattedMessage id='6zRQwF' defaultMessage='Custom Fields' />
                <br />
            </Title>
            <AttributeLabel>
                <FormattedMessage id='xLigG0' defaultMessage='Selection' />
            </AttributeLabel>
            <CloseIconThemed size={16} onClick={() => navigate('..')} />

            <HelperSelection>
                <Helper level='info'>
                    <FormattedMessage
                        id='UqwGVP'
                        defaultMessage='Please note that you can only select 200 custom fields when mapping product information.'
                    />
                </Helper>
            </HelperSelection>

            <NumberResultSelectFilter>
                <div>
                    <NumberResultCustomFields>
                        {filteredCustomFields?.length}
                        &nbsp;
                    </NumberResultCustomFields>
                    <FormattedMessage id='8quEg9' defaultMessage='results' />
                    <Pipe></Pipe>
                </div>
                <SelectedFilter
                    value={selectedFilter}
                    onChange={setSelectedFilter}
                />
            </NumberResultSelectFilter>

            {filteredCustomFields !== undefined ? (
                filteredCustomFields.length > 0 ? (
                    <>
                        <Table>
                            <Table.Header>
                                <Table.HeaderCell></Table.HeaderCell>
                                <Table.HeaderCell>
                                    <FormattedMessage
                                        id='i9kpGj'
                                        defaultMessage='Custom fields'
                                    />
                                </Table.HeaderCell>

                                <Table.HeaderCell>
                                    <FormattedMessage
                                        id='UvlPyC'
                                        defaultMessage='Attributes code'
                                    />
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    <FormattedMessage
                                        id='+U6ozc'
                                        defaultMessage='Type'
                                    />
                                </Table.HeaderCell>
                            </Table.Header>
                            <Table.Body>
                                {filteredCustomFields.map((customField) => (
                                    <Table.Row key={customField.code}>
                                        <Table.Cell>
                                            <Checkbox
                                                checked={
                                                    state[customField.code] ||
                                                    false
                                                }
                                                readOnly={
                                                    mutation.isLoading ||
                                                    (maxAttributesReached &&
                                                    !state[customField.code]
                                                        ? true
                                                        : false)
                                                }
                                                onChange={(value) =>
                                                    selectCustomField(
                                                        customField.code,
                                                        value
                                                    )
                                                }
                                            ></Checkbox>
                                        </Table.Cell>
                                        <Table.Cell>
                                            <ColoredLabel>
                                                {customField.label}
                                            </ColoredLabel>
                                        </Table.Cell>
                                        <Table.Cell>
                                            {customField.code}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {customField.type}
                                        </Table.Cell>
                                    </Table.Row>
                                ))}
                            </Table.Body>
                        </Table>
                        {filteredCustomFields.length >= 1000 ? (
                            <InformationHelper>
                                <Information
                                    illustration={<AssetsIllustration />}
                                    title={
                                        <FormattedMessage
                                            id='dT1Wyw'
                                            defaultMessage='Why some PIM attributes are missing in the list ?'
                                        />
                                    }
                                >
                                    <FormattedMessage
                                        id='kBfjzb'
                                        defaultMessage='Your PIM contains more than 1000 eligible attributes, and only 1000 could have been imported into the app. If you are missing an essential attribute that you would like to import, please get in touch with our Support team for help.'
                                    />
                                </Information>
                            </InformationHelper>
                        ) : (
                            <></>
                        )}
                    </>
                ) : (
                    <NoPimCustomFields />
                )
            ) : (
                <Loading />
            )}
        </Container>
    );
};
