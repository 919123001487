import { Helper, SectionTitle } from 'akeneo-design-system';
import { FormattedMessage } from 'react-intl';
import { useQuery } from 'react-query';
import { Spacer } from '../../../component';
import { fetchJobHistories } from '../api/fetchJobHistories';
import { JobHistoryItem } from './component/JobHistoryItem';
import {
    JobHistoryList,
    JobHistoryListSkeleton,
} from './component/JobHistoryList';
import { NoHistoryPlaceholder } from './component/NoHistoryPlaceholder';

export const HistoryTab = () => {
    const query = useQuery('fetchJobHistories', fetchJobHistories);

    return (
        <>
            <Spacer />

            <SectionTitle>
                <SectionTitle.Title>
                    <FormattedMessage
                        id='lBjsoO'
                        defaultMessage='Synchronization history'
                    />
                </SectionTitle.Title>
            </SectionTitle>

            <Helper level='info'>
                <FormattedMessage
                    id='6DmFMi'
                    defaultMessage="Here, you'll access your completed or interrupted jobs and their logs for more details."
                />
            </Helper>

            {query.isLoading && <JobHistoryListSkeleton />}
            {query.isSuccess && query.data.length > 0 ? (
                <JobHistoryList>
                    {query.data.map((jobHistory) => (
                        <JobHistoryItem
                            key={jobHistory.id}
                            jobHistory={jobHistory}
                        />
                    ))}
                </JobHistoryList>
            ) : (
                <NoHistoryPlaceholder />
            )}
        </>
    );
};
