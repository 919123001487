import { apiFetch } from '../../../util/apiFetch';

type Result = {
    data: {
        rootCategory: string;
        locale: string;
        channel: string;
        currency: string;
    } | null;
};

export const getConnectorConfig = () =>
    apiFetch<Result>('configuration/retrieveConfig').then(
        (result) => result.data
    );
