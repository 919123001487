import { Route, Routes } from 'react-router';
import { ManageMapping } from './ManageMapping';
import { ManageFamilyMapping } from './Product/ProductMappingPerFamily/ManageFamilyMapping';
import { ProductMappingProvider } from './Product/ProductMappingProvider';
import { ProductVariantMappingProvider } from './ProductVariant/ProductVariantMappingProvider';

export const NativeFields = () => (
    <ProductMappingProvider>
        <ProductVariantMappingProvider>
            <Routes>
                <Route path='/' element={<ManageMapping />} />
                <Route
                    path=':bcAttributeCode'
                    element={<ManageFamilyMapping />}
                />
            </Routes>
        </ProductVariantMappingProvider>
    </ProductMappingProvider>
);
