import { connectorTheme as theme } from 'akeneo-design-system';
import React from 'react';
import { FormattedMessage, IntlProvider } from 'react-intl';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import {
    BrowserRouter as Router,
    Navigate,
    Route,
    Routes,
} from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { AuthenticationGuard } from './AuthenticationGuard';
import {
    AppNamespaceDeactivated,
    Navigation,
    NavigationLink,
    NotFound,
    PageContent,
    PageHeader,
} from './component';
import { GoToPimLink } from './component/GoToPimLink';
import { getLocaleMessages } from './i18n/getLocaleMessages';
import { Announcements } from './page/Announcements';
import { Configuration } from './page/Configuration';
import { ErrorBoundary } from './page/ErrorBoundary';
import { Mapping } from './page/Mapping';
import { Synchronization } from './page/Synchronization';
import { getBrowserLocale } from './util/getBrowserLocale';
import { ToastProvider } from './util/toast';

const locale = getBrowserLocale();

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            useErrorBoundary: true,
            retry: false,
            refetchOnWindowFocus: false,
        },
        mutations: {
            useErrorBoundary: true,
            retry: false,
        },
    },
});

const Index = () => {
    return (
        <>
            <PageHeader>
                <Navigation>
                    <NavigationLink route='/configuration'>
                        <FormattedMessage
                            id='7OW8BT'
                            defaultMessage='Configuration'
                        />
                    </NavigationLink>
                    <NavigationLink route='/mapping'>
                        <FormattedMessage
                            id='GmyYfu'
                            defaultMessage='Mapping'
                        />
                    </NavigationLink>
                    <NavigationLink route='/synchronization'>
                        <FormattedMessage
                            id='eOsloh'
                            defaultMessage='Synchronization'
                        />
                    </NavigationLink>
                    <GoToPimLink />
                </Navigation>
                <Announcements />
            </PageHeader>
            <PageContent>
                <Routes>
                    <Route path='/configuration' element={<Configuration />} />
                    <Route path='/mapping/*' element={<Mapping />} />
                    <Route
                        path='/synchronization'
                        element={<Synchronization />}
                    />
                    <Route
                        path='*'
                        element={<Navigate to='/configuration' replace />}
                    />
                </Routes>
            </PageContent>
        </>
    );
};

function App() {
    return (
        <ThemeProvider theme={theme}>
            <IntlProvider
                locale={locale}
                defaultLocale='en'
                messages={getLocaleMessages(locale)}
                onError={() => {}}
            >
                <ToastProvider>
                    <QueryClientProvider client={queryClient}>
                        <ErrorBoundary>
                            <Router
                                basename={process.env.REACT_APP_URL_BASENAME}
                            >
                                <Routes>
                                    <Route
                                        path='/deactivated'
                                        element={
                                            <>
                                                <PageHeader></PageHeader>
                                                <PageContent>
                                                    <AppNamespaceDeactivated />
                                                </PageContent>
                                            </>
                                        }
                                    />
                                    <Route
                                        path='*'
                                        element={
                                            <AuthenticationGuard>
                                                <Index />
                                            </AuthenticationGuard>
                                        }
                                    />
                                </Routes>
                            </Router>
                        </ErrorBoundary>
                        <ReactQueryDevtools initialIsOpen={false} />
                    </QueryClientProvider>
                </ToastProvider>
            </IntlProvider>
        </ThemeProvider>
    );
}

export default App;
