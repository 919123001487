import { Button, Table } from 'akeneo-design-system';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { DateTimeLabel } from '../../../../component';
import { JobScopeLabel } from '../../component/JobScopeLabel';
import { JobTypeLabel } from '../../component/JobTypeLabel';
import { JobHistory } from '../../model/JobHistory';
import { JobHistoryStatus } from '../../model/JobHistoryStatus';
import { JobType } from '../../model/JobType';
import { JobLogModal } from '../JobLogModal';
import { JobHistoryStatusBadge } from './JobHistoryStatusBadge';
import { JobHistoryStatusMessage } from './JobHistoryStatusMessage';

const StatusMessageContainer = styled.div`
    white-space: normal;
`;

type Props = {
    jobHistory: JobHistory;
};

export const JobHistoryItem = ({ jobHistory }: Props) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <Table.Row>
            <Table.Cell>
                <DateTimeLabel dateTime={jobHistory.createdAt} />
            </Table.Cell>
            <Table.Cell>
                {DateTime.fromISO(jobHistory.endedAt)
                    .diff(DateTime.fromISO(jobHistory.createdAt), [
                        'hour',
                        'minute',
                        'second',
                    ])
                    .toFormat('hh:mm:ss')}
            </Table.Cell>
            <Table.Cell>
                <JobTypeLabel type={jobHistory.type} />
            </Table.Cell>
            <Table.Cell>
                {jobHistory.type === JobType.Differential &&
                    DateTime.fromISO(jobHistory.referenceDate).toLocaleString(
                        DateTime.DATETIME_SHORT
                    )}
            </Table.Cell>
            <Table.Cell>
                <JobScopeLabel scope={jobHistory.scope} colored />
            </Table.Cell>
            <Table.Cell>
                <JobHistoryStatusBadge status={jobHistory.status} />
            </Table.Cell>
            <Table.Cell>
                <StatusMessageContainer>
                    {jobHistory.status === JobHistoryStatus.Warning ? (
                        <JobHistoryStatusMessage
                            status={jobHistory.status}
                            warningCount={jobHistory.warningCount}
                            errorCount={jobHistory.errorCount}
                        />
                    ) : (
                        <JobHistoryStatusMessage status={jobHistory.status} />
                    )}
                </StatusMessageContainer>
            </Table.Cell>
            <Table.Cell>
                <Button
                    ghost
                    level='tertiary'
                    disabled={jobHistory.status === JobHistoryStatus.Success}
                    onClick={() => setIsOpen(true)}
                >
                    <FormattedMessage id='qkUBLC' defaultMessage='See logs' />
                </Button>
                {isOpen && (
                    <JobLogModal
                        id={jobHistory.id}
                        type={jobHistory.type}
                        scope={jobHistory.scope}
                        onClose={() => setIsOpen(false)}
                    />
                )}
            </Table.Cell>
        </Table.Row>
    );
};
