import { Immutable } from 'immer';
import { useDispatch } from '../store/StoreProvider';
import { ConnectorBcConfigForm } from '../model/ConnectorBcConfigForm';
import {
    isSaveBcConfigValidationError,
    saveBcConfig,
} from '../api/saveBcConfig';

export const useSaveBcConfigForm = () => {
    const dispatch = useDispatch();

    return async (form: Immutable<ConnectorBcConfigForm>): Promise<boolean> => {
        if (null === form.data || !form.isDirty) {
            return false;
        }

        try {
            await saveBcConfig(form.data);

            dispatch({ type: 'saveBcConfig/fulfilled' });

            return true;
        } catch (error) {
            if (!isSaveBcConfigValidationError(error)) {
                throw error;
            }

            dispatch({
                type: 'saveBcConfig/rejected',
                errors: error.data.errors,
            });

            return false;
        }
    };
};
