import { Helper, SectionTitle } from 'akeneo-design-system';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from '../store/StoreProvider';
import { MappingItem } from './component/MappingItem';
import { MappingList } from './component/MappingList';
import { HasAttributePerFamilyToggle } from './HasAttributePerFamilyToggle';
import { PimAttributeCollectionSelector } from './PimAttributeCollectionSelector';
import { PimAttributeSelector } from './PimAttributeSelector';

export const ProductMapping = () => {
    const bcAttributes = useSelector((state) => state.bcAttributes);

    return (
        <>
            <SectionTitle>
                <SectionTitle.Title>
                    <FormattedMessage
                        id='p84Gau'
                        defaultMessage='Mapping of simple products and product models'
                    />
                </SectionTitle.Title>
            </SectionTitle>

            <Helper level='info'>
                <FormattedMessage
                    id='HHRBvy'
                    defaultMessage='Respecting BigCommerce business rules is required to guarantee the synchronization.'
                />
            </Helper>

            <MappingList>
                {Object.values(bcAttributes).map((bcAttribute) => (
                    <MappingItem
                        key={bcAttribute.code}
                        bcAttribute={bcAttribute}
                    >
                        {!bcAttribute.collection && (
                            <PimAttributeSelector bcAttribute={bcAttribute} />
                        )}
                        {bcAttribute.collection && (
                            <PimAttributeCollectionSelector
                                bcAttribute={bcAttribute}
                            />
                        )}
                        <HasAttributePerFamilyToggle
                            bcAttributeCode={bcAttribute.code}
                        />
                    </MappingItem>
                ))}
            </MappingList>
        </>
    );
};
