import { Route, Routes } from 'react-router-dom';
import { CustomFieldsSelected } from './component/CustomFieldsSelected';
import { CustomFieldsSelection } from './component/CustomFieldsSelection';

export const CustomFields = () => {
    return (
        <Routes>
            <Route path='selection' element={<CustomFieldsSelection />} />
            <Route path='*' element={<CustomFieldsSelected />} />
        </Routes>
    );
};
