import { Badge } from 'akeneo-design-system';
import { FormattedMessage } from 'react-intl';
import { JobHistoryStatus } from '../../model/JobHistoryStatus';

type Props = {
    status: JobHistoryStatus;
};

export const JobHistoryStatusBadge = ({ status }: Props) => {
    switch (status) {
        case JobHistoryStatus.Success:
            return (
                <Badge level='primary'>
                    <FormattedMessage id='xrKHS6' defaultMessage='Success' />
                </Badge>
            );
        case JobHistoryStatus.Warning:
            return (
                <Badge level='warning'>
                    <FormattedMessage id='3SVI5p' defaultMessage='Warning' />
                </Badge>
            );
        case JobHistoryStatus.Error:
            return (
                <Badge level='danger'>
                    <FormattedMessage id='KN7zKn' defaultMessage='Error' />
                </Badge>
            );
    }
};
