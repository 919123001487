import {
    Button,
    CategoryIcon,
    getColor,
    getFontSize,
    LocaleIcon,
    Modal,
    SettingsIcon,
    ShopIcon,
    StatusIllustration,
} from 'akeneo-design-system';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';

const FieldList = styled.ul`
    list-style: none;
    padding: 0;
`;

const FieldItem = styled.li`
    color: ${getColor('grey', 140)};
    display: flex;
    align-items: center;
    gap: 1ch;
    font-size: ${getFontSize('bigger')};
    margin-bottom: 0.5em;
`;

const FieldIcon = styled.span`
    color: ${getColor('grey', 100)};
`;

const Description = styled.div`
    font-size: ${getFontSize('bigger')};
`;

type Props = {
    onConfirm: () => Promise<void>;
    onCancel: () => void;
};

export const DataCleanupConfirmationModal = ({
    onConfirm,
    onCancel,
}: Props) => {
    const intl = useIntl();

    const [disableButtons, setDisableButtons] = useState(false);

    const handleConfirm = async () => {
        setDisableButtons(true);
        await onConfirm();
        setDisableButtons(false);
    };

    return (
        <Modal
            closeTitle={intl.formatMessage({
                id: '47FYwb',
                defaultMessage: 'Cancel',
            })}
            onClose={() => !disableButtons && onCancel()}
            illustration={<StatusIllustration />}
        >
            <Modal.Title>
                <FormattedMessage
                    id='hYkUz2'
                    defaultMessage='You have modified one of the following fields'
                />
            </Modal.Title>

            <FieldList>
                <FieldItem>
                    <FieldIcon>
                        <LocaleIcon size={24} />
                    </FieldIcon>
                    <FormattedMessage id='RcxFT1' defaultMessage='Locale' />
                </FieldItem>
                <FieldItem>
                    <FieldIcon>
                        <ShopIcon size={24} />
                    </FieldIcon>
                    <FormattedMessage id='KeO51o' defaultMessage='Channel' />
                </FieldItem>
                <FieldItem>
                    <FieldIcon>
                        <CategoryIcon size={24} />
                    </FieldIcon>
                    <FormattedMessage
                        id='yUE0FE'
                        defaultMessage='Main category'
                    />
                </FieldItem>
                <FieldItem>
                    <FieldIcon>
                        <SettingsIcon size={24} />
                    </FieldIcon>
                    <FormattedMessage
                        id='TWdheX'
                        defaultMessage='BigCommerce storehash'
                    />
                </FieldItem>
                <FieldItem>
                    <FieldIcon>
                        <ShopIcon size={24} />
                    </FieldIcon>
                    <FormattedMessage id='LmKz3g' defaultMessage='Storefront' />
                </FieldItem>
            </FieldList>

            <Description>
                <FormattedMessage
                    id='xf+XUX'
                    defaultMessage='Synchronization issues between Akeneo PIM and your BigCommerce
                store can arise following your modifications.'
                />
                <br />
                <FormattedMessage
                    id='Uai23f'
                    defaultMessage='Please note that,
                to avoid potential synchronization issues, data persistence
                stored in the App will be erased.'
                />
                <br />
                <FormattedMessage
                    id='NC5A6B'
                    defaultMessage='Clean your BigCommerce
                store data before launching a new synchronization.'
                />
            </Description>

            <Modal.BottomButtons>
                <Button
                    level='danger'
                    onClick={onCancel}
                    disabled={disableButtons}
                >
                    <FormattedMessage id='47FYwb' defaultMessage='Cancel' />
                </Button>
                <Button
                    level='primary'
                    onClick={handleConfirm}
                    disabled={disableButtons}
                >
                    <FormattedMessage
                        id='P6Z0FA'
                        defaultMessage='Save the modifications'
                    />
                </Button>
            </Modal.BottomButtons>
        </Modal>
    );
};
