import { Button, Helper, Modal } from 'akeneo-design-system';
import { FormattedMessage, useIntl } from 'react-intl';
import { useQuery } from 'react-query';
import styled from 'styled-components';
import { Spacer } from '../../../component';
import { fetchJobLogs } from '../api/fetchJobLogs';
import { JobScopeLabel } from '../component/JobScopeLabel';
import { JobTypeLabel } from '../component/JobTypeLabel';
import { JobScope } from '../model/JobScope';
import { JobType } from '../model/JobType';
import { JobLogItem } from './component/JobLogItem';
import { JobLogList, JobLogListSkeleton } from './component/JobLogList';

const Content = styled.div`
    height: 80%;
    width: 100%;
    overflow: auto;
`;

type Props = {
    id: string;
    type: JobType;
    scope: JobScope;
    onClose: () => void;
};

export const JobLogModal = ({ id, type, scope, onClose }: Props) => {
    const intl = useIntl();

    const query = useQuery(['fetchJobLog', id], () => fetchJobLogs(id));

    const closeLabel = intl.formatMessage({
        id: 'ilr0ov',
        defaultMessage: 'Return to the list',
    });

    return (
        <Modal onClose={onClose} closeTitle={closeLabel}>
            <Modal.TopRightButtons>
                <Button ghost level='tertiary' onClick={onClose}>
                    {closeLabel}
                </Button>
            </Modal.TopRightButtons>

            <Modal.SectionTitle color='brand'>
                <FormattedMessage id='8xxH6/' defaultMessage='Job logs' />
            </Modal.SectionTitle>

            <Modal.Title>
                <JobTypeLabel type={type} />
                &nbsp;-&nbsp;
                <JobScopeLabel scope={scope} />
            </Modal.Title>

            <Content>
                <Spacer />

                <Helper level='info'>
                    <FormattedMessage
                        id='h2afTo'
                        defaultMessage='These logs display the first 1000 warnings or errors.'
                    />
                </Helper>

                <Spacer />

                {query.isLoading && <JobLogListSkeleton />}
                {query.isSuccess && (
                    <JobLogList>
                        {query.data.map((log) => (
                            <JobLogItem key={log.id} log={log} />
                        ))}
                    </JobLogList>
                )}
            </Content>
        </Modal>
    );
};
