import { ConnectionStatus } from '../model/ConnectionStatus';
import { apiFetch } from '../../../util/apiFetch';

export const testBCConnection = async () =>
    apiFetch<{ result: boolean | null }>('configuration/testBcConnection').then(
        (data) => {
            if (true === data.result) {
                return ConnectionStatus.Succeeded;
            }
            if (false === data.result) {
                return ConnectionStatus.Failed;
            }
            return ConnectionStatus.None;
        }
    );
