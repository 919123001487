import { Helper, SectionTitle } from 'akeneo-design-system';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import { useQuery } from 'react-query';
import { fetchBCVariantAttributes } from '../../api/fetchBCVariantAttributes';
import { MappingList } from '../../Product/ProductMapping/component/MappingList';
import { MappingVariantItem } from './component/MappingVariantItem';
import { PimVariantAttributeSelector } from './PimVariantAttributeSelector';
import styled from 'styled-components';

export const ProductVariantMapping = () => {
    const bcVariantAttributesQuery = useQuery(
        'fetchBcVariantAttributes',
        fetchBCVariantAttributes
    );

    return (
        <Container>
            <SectionTitle>
                <SectionTitle.Title>
                    <FormattedMessage
                        id='+WiwPE'
                        defaultMessage='Mapping of  product variants'
                    />
                </SectionTitle.Title>
            </SectionTitle>

            <div>
                <Helper level='info'>
                    <FormattedMessage
                        id='62hmBE'
                        defaultMessage='In this section you will define the mapping for the product variants (Product options in BigCommerce). As soon as you define a product variant value as a source from Akeneo PIM, it will always be recorded over a product model value.'
                    />
                </Helper>
            </div>
            {bcVariantAttributesQuery.isSuccess && (
                <MappingList>
                    {Object.values(bcVariantAttributesQuery.data).map(
                        (bcVariantAttribute) => (
                            <MappingVariantItem
                                key={bcVariantAttribute.code}
                                bcVariantAttribute={bcVariantAttribute}
                            >
                                <PimVariantAttributeSelector
                                    bcVariantAttribute={bcVariantAttribute}
                                />
                            </MappingVariantItem>
                        )
                    )}
                </MappingList>
            )}
        </Container>
    );
};

const Container = styled.div`
    margin-top: 20px;
`;
