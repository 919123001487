import { BadRequestError } from './BadRequestError';
import { ForbiddenError } from './ForbiddenError';
import { UnauthorizedError } from './UnauthorizedError';

export const apiFetch = async <T, E extends Error = Error>(
    input: string,
    init?: RequestInit
): Promise<T> => {
    const response = await fetch(
        `${process.env.REACT_APP_API_WEB_PATH}/${input}`,
        {
            ...init,
            headers: {
                ...init?.headers,
                'X-Requested-With': 'XMLHttpRequest',
            },
        }
    );

    if (!response.ok) {
        switch (response.status) {
            case 400:
            case 422:
                throw new BadRequestError<E>(await response.json());
            case 401:
                throw new UnauthorizedError();
            case 403:
                throw new ForbiddenError();
            default:
                throw new Error(`${response.status} ${response.statusText}`);
        }
    }

    return await response.json();
};
