import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useToaster } from '../../../../util/toast';
import { useSaveMapping } from '../Product/hook/useSaveMapping';
import { Immutable } from 'immer';
import { AttributeMapping } from '../model/AttributeMapping';
import { ProductVariantsMapping } from '../ProductVariant/model/ProductVariantsMapping';
import { saveProductVariantMapping } from '../api/saveProductVariantMapping';
import { useDispatch } from '../Product/store/StoreProvider';
import { useDispatch as useDispatchVariant } from '../ProductVariant/store/StoreProvider';

export const useSaveGlobalMapping = () => {
    const intl = useIntl();
    const addToast = useToaster();
    const dispatch = useDispatch();
    const dispatchVariant = useDispatchVariant();
    const saveProductMappingForm = useSaveMapping();

    const [isSaving, setIsSaving] = useState(false);

    const save = async (
        mapping: Immutable<Map<string, AttributeMapping>>,
        productVariantMapping: Immutable<ProductVariantsMapping>
    ) => {
        setIsSaving(true);

        await saveProductMappingForm(mapping);
        dispatch({ type: 'saveMapping/fulfilled' });
        await saveProductVariantMapping(productVariantMapping);
        dispatchVariant({ type: 'saveProductVariantMapping/fulfilled' });

        addToast(
            intl.formatMessage({
                id: 'o1bsib',
                defaultMessage: 'Mapping successfully updated.',
            }),
            'success'
        );

        setIsSaving(false);
    };

    return { save, isSaving };
};
