import { Helper } from 'akeneo-design-system';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useMutation, useQuery } from 'react-query';
import { Loading } from '../../../component';
import { fetchJobExecutions } from '../api/fetchJobExecutions';
import { launchJob } from '../api/launchJob';
import { JobScope } from '../model/JobScope';
import { useDispatch, useSelector } from '../store/StoreProvider';
import { JobExecutionItem } from './component/JobExecutionItem';
import { JobExecutionList } from './component/JobExecutionList';
import { useNotifyFinishedJobExecution } from './hooks/useNotifyFinishedJobExecution';

export const ExecutionTab = () => {
    const dispatch = useDispatch();
    const { filters, jobExecutions } = useSelector((state) => state);
    const mutation = useMutation(launchJob);

    useNotifyFinishedJobExecution();

    const { isLoading } = useQuery('fetchJobExecutions', fetchJobExecutions, {
        enabled: !mutation.isLoading,
        refetchInterval: 5000,
        onSuccess: (jobExecutions) => {
            dispatch({
                type: 'jobExecution/fetchJobExecutions/fulfilled',
                payload: jobExecutions,
            });
        },
    });

    const handleLaunch = (
        scope: JobScope,
        enabledProductsOnly: boolean,
        minCompleteness: number | null
    ) => {
        dispatch({ type: 'jobExecution/launchJob/pending' });
        mutation.mutate({
            scope,
            updated: filters.method === 'DIFFERENTIAL' ? filters.updated : null,
            enabledProductsOnly,
            minCompleteness,
        });
    };

    if (isLoading) {
        return <Loading />;
    }

    return (
        <>
            <Helper level='info'>
                <FormattedMessage
                    id='MkR/dZ'
                    defaultMessage='Here you can only find in progress jobs, for previous ones, go to History. For the first import, do not forget to launch the Categories import first.'
                />
            </Helper>

            <JobExecutionList>
                {Object.values(jobExecutions)
                    .sort((jobExecutionA, jobExecutionB) =>
                        jobExecutionA.scope <= jobExecutionB.scope ? -1 : 1
                    )
                    .map((jobExecution) => (
                        <JobExecutionItem
                            key={jobExecution.scope}
                            jobExecution={jobExecution}
                            onLaunch={(
                                enabledProductsOnly: boolean,
                                minCompleteness: number | null
                            ) =>
                                handleLaunch(
                                    jobExecution.scope,
                                    enabledProductsOnly,
                                    minCompleteness
                                )
                            }
                        />
                    ))}
            </JobExecutionList>
        </>
    );
};
