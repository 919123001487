import React, { useEffect, useState } from 'react';
import { JobScope } from '../../model/JobScope';
import { JobExecution } from '../../model/JobExecution';
import { useToaster } from '../../../../util/toast';
import { useIntl } from 'react-intl';
import { useSelector } from '../../store/StoreProvider';
import { JobExecutionStatus } from '../../model/JobExecutionStatus';
import { ActivityIcon } from 'akeneo-design-system';

type SynchronizationMap = { [key in JobScope]: JobExecution };

const useNotifyFinishedJobExecution = () => {
    const addToast = useToaster();
    const intl = useIntl();

    const [previousJobExecutions, setPreviousJobExecutions] =
        useState<SynchronizationMap | null>(null);

    const jobExecutions = useSelector((state) => state.jobExecutions);

    useEffect(() => {
        if (previousJobExecutions === null) {
            setPreviousJobExecutions(jobExecutions);
            return;
        }
        if (isSynchronizationFinished(previousJobExecutions, jobExecutions)) {
            addToast(
                intl.formatMessage({
                    id: 'GLyjFZ',
                    defaultMessage: 'Synchronization finished',
                }),
                'info',
                <ActivityIcon />,
                intl.formatMessage({
                    id: 'JJIcp3',
                    defaultMessage:
                        'Go to the history tab to see the status of the job.',
                })
            );
        }
        setPreviousJobExecutions(jobExecutions);
    }, [previousJobExecutions, jobExecutions, addToast, intl]);
};

const isSynchronizationFinished = (
    previousJobExecutions: SynchronizationMap,
    jobExecutions: SynchronizationMap
) => {
    const startedSynchronizationStatus = [
        JobExecutionStatus.Pending,
        JobExecutionStatus.InProgress,
    ];

    return (
        Object.values(previousJobExecutions).filter((previousJobExecution) =>
            startedSynchronizationStatus.includes(previousJobExecution.status)
        ).length > 0 &&
        Object.values(jobExecutions).filter((jobExecution) =>
            startedSynchronizationStatus.includes(jobExecution.status)
        ).length === 0
    );
};

export { useNotifyFinishedJobExecution };
