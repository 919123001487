import { apiFetch } from '../../../util/apiFetch';
import { JobScope } from '../model/JobScope';

const getScopeUrl = (
    scope: JobScope,
    updated: string | null,
    enabledProductsOnly: boolean,
    minCompleteness: number | null
) => {
    if (scope === JobScope.Category) {
        if (updated) {
            return `synchronize/categories/differentially?updatedDate=${updated}`;
        }

        return 'synchronize/categories/fully';
    }

    if (scope === JobScope.Product) {
        const status = enabledProductsOnly ? 1 : 0;
        const completeness = minCompleteness ? minCompleteness.toString() : '';
        if (updated) {
            return `synchronize/products/differentially?updatedDate=${updated}&enabledProductsOnly=${status}&minCompleteness=${completeness}`;
        }

        return `synchronize/products/fully?enabledProductsOnly=${status}&minCompleteness=${completeness}`;
    }

    if (scope === JobScope.ProductVariant) {
        if (updated) {
            return `synchronize/product-variants/differentially?updatedDate=${updated}`;
        }

        return 'synchronize/product-variants/fully';
    }

    throw new Error();
};

/**
 * @param updated Date and time ISO8601 format set to UTC timezone
 */
export const launchJob = ({
    scope,
    updated,
    enabledProductsOnly,
    minCompleteness,
}: {
    scope: JobScope;
    updated: string | null;
    enabledProductsOnly: boolean;
    minCompleteness: number | null;
}) => {
    return apiFetch(
        getScopeUrl(scope, updated, enabledProductsOnly, minCompleteness),
        {
            method: 'POST',
        }
    );
};
