import {
    AttributesIllustration,
    Link,
    Placeholder,
} from 'akeneo-design-system';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ManageCustomFieldsButton } from '../ManageCustomFieldsButton';
import styled from 'styled-components';

const MessageContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
`;

export const NoCustomFields = () => {
    const intl = useIntl();

    const title = intl.formatMessage({
        id: 'cXn7ci',
        defaultMessage: 'No custom fields for the moment',
    });

    return (
        <>
            <Placeholder
                illustration={<AttributesIllustration />}
                size='large'
                title={title}
            />
            <MessageContainer>
                <FormattedMessage
                    id='xFm/WF'
                    defaultMessage='To create a custom field, use "Manage Custom Fields" button.'
                />
                <Link href='https://help.akeneo.com/bigcommerce/articles/02-mapping.html'>
                    <FormattedMessage
                        id='43uZEt'
                        defaultMessage='Visit our Help Center for more information'
                    />
                </Link>

                <ManageCustomFieldsButton />
            </MessageContainer>
        </>
    );
};
