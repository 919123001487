import {
    Field,
    Helper,
    Link,
    SectionTitle,
    SelectInput,
    Tag,
    Tags,
} from 'akeneo-design-system';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from '../store/StoreProvider';
import { BcConfig } from '../model/BcConfig';
import { ConnectionStatus } from '../model/ConnectionStatus';
import styled from 'styled-components';
import { Skeleton } from '../../../component';
import { getBCConfigOptions } from '../api/getBCConfigOptions';

const LabelSkeleton = styled(Skeleton)`
    height: 20px;
    margin-bottom: 10px;
    width: 25%;
`;

const FieldSkeleton = styled(Skeleton)`
    height: 40px;
`;

const defaultBcConnectorConfig: BcConfig = {
    storefrontId: '',
    displayOptionTypeCode: '',
};

export const BCVariantDisplayForm = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const { connectorBcConfig, isBCCredentialsInvalid } = useSelector(
        (state) => ({
            connectorBcConfig:
                state.connectorBcConfigForm.data || defaultBcConnectorConfig,
            isBCCredentialsInvalid:
                state.bcConnectionStatus !== ConnectionStatus.Succeeded,
        })
    );

    const { isFetching, data } = useQuery(
        'configuration/retrieveBCConfigOptions',
        getBCConfigOptions,
        {
            enabled: !isBCCredentialsInvalid,
        }
    );
    const handleChange = (bcConfig: BcConfig) =>
        dispatch({
            type: 'bcConfigForm/changed',
            bcConfig,
        });

    const displayOptionTypeExist = (displayOptionTypeCode: string) =>
        data?.displayOptionTypes?.some(
            (displayOptionType) =>
                displayOptionType.displayOptionTypeCode ===
                displayOptionTypeCode
        );

    return (
        <>
            <SectionTitle>
                <Tags>
                    <Tag tint='dark_blue'>BigCommerce</Tag>
                </Tags>
                <SectionTitle.Title>
                    <FormattedMessage
                        id='Dzxuj7'
                        defaultMessage='Variants display'
                    />
                </SectionTitle.Title>
                <SectionTitle.Spacer />
            </SectionTitle>

            <Helper level='info'>
                <FormattedMessage
                    id='2dNJlP'
                    defaultMessage='To understand the usability use case for the variants display.'
                />{' '}
                <Link href='https://help.akeneo.com/bigcommerce/articles/01-configuration-set-up.html'>
                    <FormattedMessage
                        id='iMmZy3'
                        defaultMessage='Check out our Help Center for more information.'
                    />
                </Link>
            </Helper>

            <br />

            <form>
                {!isFetching ? (
                    <Field
                        label={intl.formatMessage({
                            id: 'Dzxuj7',
                            defaultMessage: 'Variants display',
                        })}
                    >
                        <SelectInput
                            placeholder={intl.formatMessage({
                                id: 'uMSLV8',
                                defaultMessage: 'Select the Variants display',
                            })}
                            openLabel=''
                            value={
                                !isBCCredentialsInvalid &&
                                displayOptionTypeExist(
                                    connectorBcConfig.displayOptionTypeCode
                                )
                                    ? connectorBcConfig.displayOptionTypeCode
                                    : ''
                            }
                            data-testid='bc-storefront'
                            onChange={(displayOptionTypeCode: string) =>
                                handleChange({
                                    ...connectorBcConfig,
                                    displayOptionTypeCode,
                                })
                            }
                            readOnly={isBCCredentialsInvalid}
                            clearable={false}
                            emptyResultLabel={intl.formatMessage({
                                id: 'igGVz3',
                                defaultMessage: 'No Variants display found',
                            })}
                        >
                            {data?.displayOptionTypes?.map((value) => (
                                <SelectInput.Option
                                    key={value.displayOptionTypeCode}
                                    value={value.displayOptionTypeCode}
                                >
                                    {value.displayOptionTypeLabel}
                                </SelectInput.Option>
                            ))}
                        </SelectInput>
                        {connectorBcConfig.displayOptionTypeCode !== null &&
                        !displayOptionTypeExist(
                            connectorBcConfig.displayOptionTypeCode
                        ) &&
                        !isBCCredentialsInvalid ? (
                            <Helper inline level='error'>
                                {intl.formatMessage({
                                    id: 'YjrbNs',
                                    defaultMessage:
                                        'The previously selected variant display does not exist anymore. Please choose a new variant display.',
                                })}
                            </Helper>
                        ) : (
                            <></>
                        )}
                    </Field>
                ) : (
                    <>
                        <LabelSkeleton />
                        <FieldSkeleton />
                    </>
                )}

                <br />
            </form>
        </>
    );
};
