import { apiFetch } from '../../../util/apiFetch';

type Result = {
    rootCategory: { [code: string]: string };
    locale: { [code: string]: string };
    channel: { [code: string]: string };
    currency: { [code: string]: string };
};

export const getConnectorConfigOptions = () =>
    apiFetch<Result>('configuration/retrieveConfigOptions');
