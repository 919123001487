import { AllowedPimAttributeMultiselect } from '../component/AllowedPimAttributeMultiselect';
import { BCAttribute } from '../../model/BCAttribute';
import { selectAttributeMapping } from '../selector/selectAttributeMapping';
import { useDispatch, useSelector } from '../store/StoreProvider';

type Props = {
    bcAttribute: BCAttribute;
};

export const PimAttributeCollectionSelector = ({ bcAttribute }: Props) => {
    const dispatch = useDispatch();

    const attributeMapping = useSelector((state) => {
        const attributeMapping = selectAttributeMapping(bcAttribute.code)(
            state
        );
        if (!attributeMapping.hasCollectionOfAttribute) {
            throw new Error();
        }

        return attributeMapping;
    });

    const handleChange = (pimAttributeCodes: string[]) =>
        dispatch({
            type: 'mapping/PimAttributeCodesChanged',
            bcAttributeCode: bcAttribute.code,
            pimAttributeCodes,
        });

    return (
        <AllowedPimAttributeMultiselect
            bcAttribute={bcAttribute}
            selectedPimAttributeCodes={
                attributeMapping.hasAttributePerFamily
                    ? []
                    : (attributeMapping.pimAttributeCodes as string[])
            }
            disabled={attributeMapping.hasAttributePerFamily}
            onChange={handleChange}
        />
    );
};
