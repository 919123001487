import {
    CategoryIcon,
    CopyIcon,
    ProductIcon,
    useTheme,
} from 'akeneo-design-system';
import React from 'react';
import { JobScope } from '../../model/JobScope';

type Props = {
    scope: JobScope;
};

export const JobScopeIcon = ({ scope }: Props) => {
    const theme = useTheme();

    let icon;
    switch (scope) {
        case JobScope.Category:
            icon = <CategoryIcon />;
            break;
        case JobScope.Product:
            icon = <ProductIcon />;
            break;
        case JobScope.ProductVariant:
            icon = <CopyIcon />;
            break;
    }

    return React.cloneElement(icon, {
        color: theme.color.grey100,
        width: 30,
        height: 30,
    });
};
