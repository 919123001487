import { apiFetch } from '../../../util/apiFetch';
import { JobHistory } from '../model/JobHistory';
import { JobHistoryStatus } from '../model/JobHistoryStatus';
import { JobScope } from '../model/JobScope';
import { JobType } from '../model/JobType';

type Result = Array<{
    id: string;
    created_at: string;
    ended_at: string;
    type: 'FULL' | 'DIFFERENTIAL';
    reference_date: string;
    scope: 'CATEGORY' | 'PRODUCT' | 'PRODUCT_VARIANT';
    status: 'SUCCESS' | 'WARNING' | 'ERROR';
    warning_count: number;
    error_count: number;
}>;

export const fetchJobHistories = (): Promise<JobHistory[]> =>
    apiFetch<Result>('synchronization/retrieveExecutedSynchronizations').then(
        (result) =>
            result.map((data) => {
                const jobHistory: Partial<JobHistory> = {
                    id: data.id,
                    createdAt: data.created_at,
                    endedAt: data.ended_at,
                    scope:
                        data.scope === 'CATEGORY'
                            ? JobScope.Category
                            : data.scope === 'PRODUCT'
                            ? JobScope.Product
                            : JobScope.ProductVariant,
                    type:
                        data.type === 'FULL'
                            ? JobType.Full
                            : JobType.Differential,
                    status:
                        data.status === 'SUCCESS'
                            ? JobHistoryStatus.Success
                            : data.status === 'WARNING'
                            ? JobHistoryStatus.Warning
                            : JobHistoryStatus.Error,
                };
                if (jobHistory.type === JobType.Differential) {
                    jobHistory.referenceDate = data.reference_date;
                }
                if (jobHistory.status === JobHistoryStatus.Warning) {
                    jobHistory.warningCount = data.warning_count;
                    jobHistory.errorCount = data.error_count;
                }

                return jobHistory as JobHistory;
            })
    );
