import { Immutable, produce } from 'immer';
import { DateTime } from 'luxon';
import { JobExecutionStatus } from '../model/JobExecutionStatus';
import { dateTimeToIso8601 } from '../../../util/dateTimeToIso8601';
import { JobScope } from '../model/JobScope';
import { JobExecution } from '../model/JobExecution';

export type Filters = {
    method: 'FULL' | 'DIFFERENTIAL';
    updated: string;
};

export type State = Immutable<{
    filters: Filters;
    jobExecutions: {
        [key in JobScope]: JobExecution;
    };
}>;

export const initialState: State = {
    filters: {
        method: 'FULL',
        updated: dateTimeToIso8601(DateTime.utc()),
    },
    jobExecutions: {
        [JobScope.Category]: {
            scope: JobScope.Category,
            status: JobExecutionStatus.Disabled,
        },
        [JobScope.Product]: {
            scope: JobScope.Product,
            status: JobExecutionStatus.Disabled,
        },
        [JobScope.ProductVariant]: {
            scope: JobScope.ProductVariant,
            status: JobExecutionStatus.Disabled,
        },
    },
};

export type Action =
    | { type: 'filters/changed'; payload: Filters }
    | {
          type: 'jobExecution/fetchJobExecutions/fulfilled';
          payload: { [key in JobScope]: JobExecution };
      }
    | { type: 'jobExecution/launchJob/pending' };

export const reducer = produce<(draft: State, action: Action) => State>(
    (draft, action) => {
        switch (action.type) {
            case 'filters/changed':
                draft.filters = action.payload;
                break;

            case 'jobExecution/fetchJobExecutions/fulfilled':
                draft.jobExecutions = action.payload;
                break;

            case 'jobExecution/launchJob/pending':
                Object.values(draft.jobExecutions).forEach(
                    (jobExecution) =>
                        (jobExecution.status = JobExecutionStatus.Disabled)
                );
                break;
        }

        return draft;
    }
);
