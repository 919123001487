import React from 'react';
import { useQuery } from 'react-query';
import { Loading } from '../../../../component';
import { getBCAttributes } from '../api/getBCAttributes';
import { getMapping } from '../api/getMapping';
import { getPimAttributes } from '../api/getPimAttributes';
import { getPimFamilies } from '../api/getPimFamilies';
import { initialState, reducer } from './store/reducer';
import { DispatchContext, StateContext } from './store/StoreProvider';

type Props = {
    children: React.ReactNode;
};

export const ProductMappingProvider = ({ children }: Props) => {
    const [state, dispatch] = React.useReducer(reducer, initialState);

    const { isFetching } = useQuery(
        'fetchAllData',
        () =>
            Promise.all([
                getBCAttributes(),
                getPimAttributes(),
                getPimFamilies(),
                getMapping(),
            ]),
        {
            onSuccess: ([bcAttributes, pimAttributes, pimFamilies, mapping]) =>
                dispatch({
                    type: 'fetchAllData/fulfilled',
                    bcAttributes,
                    pimAttributes,
                    pimFamilies,
                    mapping,
                }),
        }
    );

    if (isFetching) {
        return <Loading />;
    }

    return (
        <StateContext.Provider value={state}>
            <DispatchContext.Provider value={dispatch}>
                {children}
            </DispatchContext.Provider>
        </StateContext.Provider>
    );
};
