import { Button, Modal, ProductsIllustration } from 'akeneo-design-system';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { useState } from 'react';
import { JobScope } from '../../model/JobScope';
import { JobScopeLabel } from '../../component/JobScopeLabel';
import { FiltersForm } from './FiltersForm';

type Props = {
    closeModal: () => void;
    onLaunch: (
        enabledProductsOnly: boolean,
        minCompleteness: number | null
    ) => void;
    scope: JobScope;
};

const JobExecutionModal = ({ closeModal, onLaunch, scope }: Props) => {
    const [enabledProductsOnly, setEnabledProductsOnly] =
        useState<boolean>(false);
    const [minCompleteness, setMinCompleteness] = useState<number | null>(null);

    const handleLaunchSynchronization = () => {
        onLaunch(enabledProductsOnly, minCompleteness);
        closeModal();
    };
    const intl = useIntl();
    return (
        <Modal
            onClose={closeModal}
            closeTitle={intl.formatMessage({
                id: '47FYwb',
                defaultMessage: 'Cancel',
            })}
            illustration={<ProductsIllustration />}
        >
            <Modal.SectionTitle color='brand'>
                <FormattedMessage
                    id='hIfuj9'
                    defaultMessage='Filters definition'
                />
            </Modal.SectionTitle>
            <Modal.Title>
                <JobScopeLabel scope={scope} />{' '}
                {intl.formatMessage({
                    id: 'eOsloh',
                    defaultMessage: 'Synchronization',
                })}
            </Modal.Title>

            <FiltersForm
                scope={scope}
                enabledProductsOnly={enabledProductsOnly}
                setEnabledProductsOnly={setEnabledProductsOnly}
                minCompleteness={minCompleteness}
                setMinCompleteness={setMinCompleteness}
            />

            <Modal.BottomButtons>
                <Button level='tertiary' onClick={closeModal}>
                    <FormattedMessage id='47FYwb' defaultMessage='Cancel' />
                </Button>
                <Button level='primary' onClick={handleLaunchSynchronization}>
                    <FormattedMessage
                        id='Nwc2RN'
                        defaultMessage='Launch the synchronization'
                    />
                </Button>
            </Modal.BottomButtons>
        </Modal>
    );
};

export { JobExecutionModal };
