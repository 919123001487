import { apiFetch, BadRequestError } from '../../../util/apiFetch';

type Config = {
    rootCategory: string | null;
    locale: string | null;
    channel: string | null;
    currency: string | null;
};

export const saveConnectorConfig = (config: Config) =>
    apiFetch('configuration/saveConfig', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            rootCategory: config.rootCategory || '',
            locale: config.locale || '',
            channel: config.channel || '',
            currency: config.currency || '',
        }),
    });

export const isSaveConnectorConfigValidationError = (
    error: unknown
): error is BadRequestError<{
    errors: {
        rootCategory: string[];
        locale: string[];
        channel: string[];
        currency: string[];
    };
}> => error instanceof BadRequestError;
