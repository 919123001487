import styled from 'styled-components';

export const FlexLayout = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 40px;
`;

export const FlexColumn = styled.div`
    display: flex;
    flex-basis: 100%;
    flex-direction: column;
    flex: 1;
`;
