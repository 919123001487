import { apiFetch, BadRequestError } from '../../../util/apiFetch';

type BcConfig = {
    storefrontId: string | null;
    displayOptionTypeCode: string | null;
};

export const saveBcConfig = (bcConfig: BcConfig) =>
    apiFetch('configuration/saveBcConfig', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            storefront_id: bcConfig.storefrontId || null,
            display_option_type_code: bcConfig.displayOptionTypeCode || null,
        }),
    });
export const isSaveBcConfigValidationError = (
    error: unknown
): error is BadRequestError<{
    errors: {
        storefrontId: string[];
        displayOptionTypeCode: string[];
    };
}> => error instanceof BadRequestError;
