import React from 'react';
import { useQuery } from 'react-query';
import { Loading } from '../../component';
import { ConnectionStatus } from './model/ConnectionStatus';
import { useGetConnectorConfig } from './hook/useGetConnectorConfig';
import { testBCConnection } from './api/testBCConnection';
import { useGetBCConfig } from './hook/useGetBCConfig';

type Props = {
    children: React.ReactNode;
    fallback: (
        isBCCredentialsInvalid: boolean,
        isConfigInvalid: boolean,
        isBcConfigInvalid: boolean
    ) => React.ReactNode;
};

export const ValidConfigurationGuard = ({ children, fallback }: Props) => {
    const testBCCredentialsQuery = useQuery(
        'Configuration/testBcConnection',
        testBCConnection
    );
    const isBCCredentialsInvalid =
        testBCCredentialsQuery.data !== ConnectionStatus.Succeeded;

    const getConnectorConfigQuery = useGetConnectorConfig();
    const getBcConfigQuery = useGetBCConfig();
    const isConfigInvalid = !getConnectorConfigQuery.data;
    const isBcConfigInvalid = !getBcConfigQuery.data?.storefrontId;

    if (
        testBCCredentialsQuery.isLoading ||
        getConnectorConfigQuery.isLoading ||
        getBcConfigQuery.isLoading
    ) {
        return <Loading />;
    }

    if (isBCCredentialsInvalid || isConfigInvalid || isBcConfigInvalid) {
        return (
            <>
                {fallback(
                    isBCCredentialsInvalid,
                    isConfigInvalid,
                    isBcConfigInvalid
                )}
            </>
        );
    }

    return <>{children}</>;
};
