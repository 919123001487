import { apiFetch } from '../../../../util/apiFetch';
import { Immutable } from 'immer';

type SaveAttributeMapping = {
    hasAttributePerFamily: boolean;
    hasCollectionOfAttribute: boolean;
    data:
        | string
        | readonly string[]
        | { [pimFamilyCode: string]: string | null }
        | { [pimFamilyCode: string]: string[] | null };
};

export type Data = {
    [bcAttributeCode: string]: Immutable<SaveAttributeMapping>;
};

export const saveMapping = (data: Data) =>
    apiFetch('mapping', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });
