import { apiFetch } from '../../../../util/apiFetch';

export type Result = {
    data: Array<{
        code: string;
        type: string;
        label: string;
    }>;
};

export const retrievePimAttributesForCustomFieldsSelection = () =>
    apiFetch<Result>(
        'mapping/retrievePimAttributesForCustomFieldsSelection'
    ).then((result) => result.data);
