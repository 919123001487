import { Badge } from 'akeneo-design-system';
import { FormattedMessage } from 'react-intl';

type Props = {
    status: 'WARNING' | 'ERROR';
};

export const JobLogStatusBadge = ({ status }: Props) => {
    switch (status) {
        case 'WARNING':
            return (
                <Badge level='warning'>
                    <FormattedMessage id='3SVI5p' defaultMessage='Warning' />
                </Badge>
            );
        case 'ERROR':
            return (
                <Badge level='danger'>
                    <FormattedMessage id='KN7zKn' defaultMessage='Error' />
                </Badge>
            );
    }
};
