import { Badge, Button, Helper, Table } from 'akeneo-design-system';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import { BCAttribute } from '../../../model/BCAttribute';
import { MappingStatus } from '../../../model/MappingStatus';
import { selectAttributeMapping } from '../../selector/selectAttributeMapping';
import { useSelector } from '../../store/StoreProvider';

const BCAttributeContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 26px;
`;

const PimAttributeCell = styled(Table.Cell)`
    overflow: initial;
`;

const PimAttributeContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 6px;
    width: 300px;
`;

const ButtonContainer = styled.div`
    text-align: right;
    width: 100%;
`;

type Props = {
    bcAttribute: BCAttribute;
    children: React.ReactNode;
};

export const MappingItem = ({ bcAttribute, children }: Props) => {
    const navigate = useNavigate();

    const attributeMapping = useSelector(
        selectAttributeMapping(bcAttribute.code)
    );

    const pimFamilyCount = useSelector(
        (state) => Object.keys(state.pimFamilies).length
    );

    return (
        <Table.Row data-testid={bcAttribute.code}>
            <Table.Cell>
                <BCAttributeContainer>
                    <div>{bcAttribute.label}</div>
                    <Helper inline level='info'>
                        {bcAttribute.isRequired ? (
                            <FormattedMessage
                                id='otgkyu'
                                defaultMessage='Required, source data PIM allowed attribute types: {types}'
                                values={{
                                    types: bcAttribute.pimAllowedTypes.join(
                                        ', '
                                    ),
                                }}
                            />
                        ) : (
                            <FormattedMessage
                                id='L9laDs'
                                defaultMessage='Source data PIM allowed attribute types: {types}'
                                values={{
                                    types: bcAttribute.pimAllowedTypes.join(
                                        ', '
                                    ),
                                }}
                            />
                        )}
                        {bcAttribute.constraint && (
                            <>
                                <br />
                                <FormattedMessage
                                    id='u8wsGp'
                                    defaultMessage='Data value constraint: {constraint}'
                                    values={{
                                        constraint: bcAttribute.constraint,
                                    }}
                                />
                            </>
                        )}
                    </Helper>
                </BCAttributeContainer>
            </Table.Cell>

            <PimAttributeCell>
                <PimAttributeContainer>{children}</PimAttributeContainer>
            </PimAttributeCell>

            <Table.Cell>
                {MappingStatus.Complete === attributeMapping.status && (
                    <Badge level='primary'>
                        <FormattedMessage
                            id='U78NhE'
                            defaultMessage='Complete'
                        />
                    </Badge>
                )}
                {MappingStatus.InProgress === attributeMapping.status &&
                    attributeMapping.hasAttributePerFamily && (
                        <Badge level='secondary'>
                            <FormattedMessage
                                id='gTARrW'
                                defaultMessage='In progress {cur}/{max}'
                                values={{
                                    cur: Object.values(
                                        attributeMapping.hasCollectionOfAttribute
                                            ? attributeMapping.pimAttributeCodesPerFamily
                                            : attributeMapping.pimAttributeCodePerFamily
                                    ).filter(
                                        (pimAttributeCodes) =>
                                            null !== pimAttributeCodes
                                    ).length,
                                    max: pimFamilyCount,
                                }}
                            />
                        </Badge>
                    )}
            </Table.Cell>

            <Table.Cell>
                <ButtonContainer>
                    {attributeMapping.hasAttributePerFamily && (
                        <Button
                            ghost
                            level='secondary'
                            onClick={() => navigate(bcAttribute.code)}
                        >
                            <FormattedMessage
                                id='SUOxRz'
                                defaultMessage='Manage family mapping'
                            />
                        </Button>
                    )}
                </ButtonContainer>
            </Table.Cell>
        </Table.Row>
    );
};
