import { apiFetch } from '../../../../util/apiFetch';

export const saveCustomFields = (customFieldSelection: string[]) => {
    return apiFetch('mapping/saveCustomFields', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(customFieldSelection),
    });
};
