import { useQuery } from 'react-query';
import { apiFetch, UnauthorizedError } from './util/apiFetch';

type Props = {
    children: React.ReactNode;
};

export const AuthenticationGuard = ({ children }: Props) => {
    const { isLoading } = useQuery<unknown, UnauthorizedError | unknown>(
        'challengeAuthentication',
        () => apiFetch('retrievePimUrl'),
        {
            useErrorBoundary: false,
        }
    );

    if (isLoading) {
        return <></>;
    }

    return <>{children}</>;
};
