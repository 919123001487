import { FormattedMessage } from 'react-intl';
import { JobHistoryStatus } from '../../model/JobHistoryStatus';

type Props =
    | {
          status: JobHistoryStatus.Success | JobHistoryStatus.Error;
      }
    | {
          status: JobHistoryStatus.Warning;
          warningCount: number;
          errorCount: number;
      };

export const JobHistoryStatusMessage = (props: Props) => {
    switch (props.status) {
        case JobHistoryStatus.Success:
            return (
                <FormattedMessage
                    id='4TrdVJ'
                    defaultMessage='Your job has been completed successfully, without warnings or errors.'
                />
            );
        case JobHistoryStatus.Warning:
            return (
                <FormattedMessage
                    id='tEOqXq'
                    defaultMessage='Your jobs has been completed with {warningCount} warnings and {errorCount} errors.'
                    values={{
                        warningCount: props.warningCount,
                        errorCount: props.errorCount,
                    }}
                />
            );
        case JobHistoryStatus.Error:
            return (
                <FormattedMessage
                    id='snJnTC'
                    defaultMessage='Your job could not be completed and has been stopped.'
                />
            );
    }
};
