import { Button, Table, useBooleanState } from 'akeneo-design-system';
import { DateTime } from 'luxon';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { JobScopeLabel } from '../../component/JobScopeLabel';
import { JobTypeLabel } from '../../component/JobTypeLabel';
import { JobExecution } from '../../model/JobExecution';
import { JobExecutionStatus } from '../../model/JobExecutionStatus';
import { JobType } from '../../model/JobType';
import { JobExecutionDuration } from './JobExecutionDuration';
import { JobExecutionStatusBadge } from './JobExecutionStatusBadge';
import { JobScopeIcon } from './JobScopeIcon';
import { JobExecutionModal } from './JobExecutionModal';

const IconContainer = styled.div`
    align-items: center;
    display: flex;
    height: 44px;
    padding-right: 20px;
`;

const ButtonContainer = styled.div`
    text-align: right;
    width: 100%;
`;

type Props = {
    jobExecution: JobExecution;
    onLaunch: (
        enabledProductsOnly: boolean,
        minCompleteness: number | null
    ) => void;
};

export const JobExecutionItem = ({ jobExecution, onLaunch }: Props) => {
    const [isModalOpen, openModal, closeModal] = useBooleanState(false);

    return (
        <>
            <Table.Row data-testid={jobExecution.scope}>
                <Table.Cell>
                    <IconContainer>
                        <JobScopeIcon scope={jobExecution.scope} />
                    </IconContainer>
                    <JobScopeLabel scope={jobExecution.scope} colored />
                </Table.Cell>

                <Table.Cell>
                    <JobExecutionStatusBadge status={jobExecution.status} />
                </Table.Cell>

                {jobExecution.status === JobExecutionStatus.Pending ||
                jobExecution.status === JobExecutionStatus.InProgress ? (
                    <>
                        <Table.Cell>
                            <JobExecutionDuration
                                createdAt={jobExecution.createdAt}
                            />
                        </Table.Cell>

                        <Table.Cell>
                            <JobTypeLabel type={jobExecution.type} />
                        </Table.Cell>

                        <Table.Cell>
                            {jobExecution.type === JobType.Differential &&
                                DateTime.fromISO(
                                    jobExecution.referenceDate
                                ).toLocaleString(DateTime.DATETIME_SHORT)}
                        </Table.Cell>
                    </>
                ) : (
                    <>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                    </>
                )}

                <Table.Cell>
                    <ButtonContainer>
                        <Button
                            ghost
                            level='tertiary'
                            onClick={openModal}
                            disabled={
                                jobExecution.status !== JobExecutionStatus.Ready
                            }
                        >
                            <FormattedMessage
                                id='Nwc2RN'
                                defaultMessage='Launch the synchronization'
                            />
                        </Button>
                    </ButtonContainer>
                </Table.Cell>
            </Table.Row>
            {isModalOpen && (
                <JobExecutionModal
                    closeModal={closeModal}
                    onLaunch={onLaunch}
                    scope={jobExecution.scope}
                />
            )}
        </>
    );
};
