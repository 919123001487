import { Badge, TabBar } from 'akeneo-design-system';
import { FormattedMessage } from 'react-intl';
import {
    matchPath,
    Navigate,
    Route,
    Routes,
    useLocation,
    useNavigate,
} from 'react-router-dom';
import styled from 'styled-components';
import { ValidConfigurationGuard } from '../Configuration';
import { RunningSynchronizationGuard } from '../Synchronization/RunningSynchronizationGuard';
import { DisabledMapping } from './component/DisabledMapping';
import { InvalidConfiguration } from './component/InvalidConfiguration';
import { CustomFields } from './CustomFields/CustomFields';
import { NativeFields } from './NativeFields/NativeFields';

const Container = styled.div`
    margin-top: 32px;
`;

const CUSTOM_FIELDS_FEATURE_FLAG = true;

export const Mapping = () => {
    const location = useLocation();
    const navigate = useNavigate();

    return (
        <RunningSynchronizationGuard fallback={() => <DisabledMapping />}>
            <ValidConfigurationGuard
                fallback={(
                    isBCCredentialsInvalid,
                    isConfigInvalid,
                    isBcConfigInvalid
                ) => (
                    <InvalidConfiguration
                        isBCCredentialsInvalid={isBCCredentialsInvalid}
                        isConfigInvalid={isConfigInvalid}
                        isBcConfigInvalid={isBcConfigInvalid}
                    />
                )}
            >
                <Container>
                    <TabBar moreButtonTitle='More'>
                        <TabBar.Tab
                            isActive={
                                null !==
                                matchPath(
                                    '/mapping/native-field/*',
                                    location.pathname
                                )
                            }
                            onClick={() => navigate('native-field')}
                        >
                            <FormattedMessage
                                id='kS5JXH'
                                defaultMessage='Native fields'
                            />
                        </TabBar.Tab>
                        {CUSTOM_FIELDS_FEATURE_FLAG && (
                            <TabBar.Tab
                                isActive={
                                    null !==
                                    matchPath(
                                        '/mapping/custom-field/*',
                                        location.pathname
                                    )
                                }
                                onClick={() => navigate('custom-field')}
                            >
                                <FormattedMessage
                                    id='i9kpGj'
                                    defaultMessage='Custom fields'
                                />

                                <Badge level='primary'>
                                    <FormattedMessage
                                        id='bW7B87'
                                        defaultMessage='New'
                                    />
                                </Badge>
                            </TabBar.Tab>
                        )}
                    </TabBar>

                    <Routes>
                        <Route
                            path='native-field/*'
                            element={<NativeFields />}
                        />

                        <Route
                            path='custom-field/*'
                            element={<CustomFields />}
                        />
                        <Route
                            path='*'
                            element={<Navigate to='native-field' replace />}
                        />
                    </Routes>
                </Container>
            </ValidConfigurationGuard>
        </RunningSynchronizationGuard>
    );
};
