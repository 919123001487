import { ApiIllustration, Placeholder } from 'akeneo-design-system';
import { useIntl } from 'react-intl';

export const InvalidConfiguration = () => {
    const intl = useIntl();

    return (
        <Placeholder
            illustration={<ApiIllustration />}
            size='large'
            title={intl.formatMessage({
                id: 'yYmg2v',
                defaultMessage:
                    'To access the synchronization page, please start by configuring and mapping the connector.',
            })}
        />
    );
};
