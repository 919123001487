import { apiFetch } from '../../../../util/apiFetch';

export type Result = {
    data: Array<{
        code: string;
        type: string;
        label: string;
    }>;
};

export const getSelectedCustomFields = () =>
    apiFetch<Result>('mapping/retrieveCustomFields').then(
        (result) => result.data
    );
