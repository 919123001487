import { Dispatch } from 'react';
import { useQuery } from 'react-query';
import { getBCCredentials } from '../api/getBCCredentials';
import { getConnectorConfig } from '../api/getConnectorConfig';
import { testBCConnection } from '../api/testBCConnection';
import { Action } from '../store/reducer';
import { getBcConfig } from '../api/getBcConfig';

export const fetchConfigurationQueryKey = 'Configuration/fetchConfiguration';

export const useFetchConfiguration = (dispatch: Dispatch<Action>) => {
    return useQuery(
        fetchConfigurationQueryKey,
        () =>
            Promise.all([
                getBCCredentials(),
                getConnectorConfig(),
                getBcConfig(),
                testBCConnection(),
            ]),
        {
            onSuccess: ([
                bcCredentials,
                connectorConfig,
                connectorBcConfig,
                bcConnectionStatus,
            ]) => {
                dispatch({
                    type: 'fetchConfiguration/fulfilled',
                    payload: {
                        bcCredentials,
                        connectorConfig,
                        connectorBcConfig,
                        bcConnectionStatus,
                    },
                });
            },
        }
    );
};
