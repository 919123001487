import { ApiIllustration, Placeholder } from 'akeneo-design-system';
import React from 'react';
import { useIntl } from 'react-intl';

export const DisabledMapping = () => {
    const intl = useIntl();

    const title = intl.formatMessage({
        id: '8EdVmR',
        defaultMessage:
            'Your mapping page is temporarily disabled as the import job is in progress.',
    });

    return (
        <Placeholder
            illustration={<ApiIllustration />}
            size='large'
            title={title}
        />
    );
};
