import { apiFetch } from '../../../../util/apiFetch';

export type Result = {
    [code: string]: {
        code: string;
        collection: boolean;
        type: string;
        label: string;
        constraint: string;
        pimAllowedTypes: string[];
        isRequired: boolean;
    };
};

export const getBCAttributes = () => apiFetch<Result>('mapping/bc-attributes');
